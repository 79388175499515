.rce-container-mbox {
    &.programmable_message {
        .rce-mbox-photo {
            margin: 0;
            padding: 0 20px;
            border-radius: 8px;
        }
    }
    .rce-mbox-pm {
        &.rce-mbox {
            padding: 0 20px 3px;
            .rce-mbox-body {
                background-color: $lemon-merengue;
                border-radius: 8px;
                padding: 16px;
                display: flex;
                svg {
                    margin-right: 8px;
                }
                .rce-mbox-text p {
                    line-height: 24px;
                }
            }     
        }
        .rce-mbox-sender-name {
            span {
                text-transform: uppercase;
                color: $dim-gray;
                @extend .overline;
            }
        }
    }
}