.location-search-current {
    border: none;
    box-shadow: none;
    outline: none;
    background: inherit;
    padding: 0;
    background-color: white;
    border-radius: 50%;
    width: 45px;
    height: 45px;
}