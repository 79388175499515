.Job_Info_Thumb{
  width: 48px;
  height: 48px;
}
.Img_Box{
  width: 10%;
  img{
    width: 24px;
    height: 24px;
  }
}
.Title_Box{
  width: 90%;
}