.Home_Search_Product{
  display: flex;
  flex: 1 1 100%;
  margin: 8px 16px;
  height: 40px;
  border-bottom: 1px solid #ededed;
  &:last-child {
    margin-bottom: 50px;
  }
  .H_S_P_Icon{
    flex: 1 1 20%;
    height: 100%;
    img{
      width: 32px;
      height: 32px;
      margin: 0 16px;
    }
  }
  .H_S_P_Text{
    flex: 1 1 80%;
    height: 100%;
    margin: 4px 0;
    color: #898E92;
    font-size: 13px;

    h3{
      font-size: 13px;
      font-weight: 600;
      color: #bfc3c6;
      margin: 18px 0 0 0;
    }
    h5{
      font-size: 10px;
      font-weight: 600;
      color: #bfc3c6;
      margin: 5px 0 0 0;

    }
  }
  .S_P_P_Product{
    flex: 1 1 80%;
    height: 100%;
    margin: 4px 0;
    color: #3B424A;
    padding: 0 12px 0 0;
    text-align: left;
    @extend .subtitle1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    h3{
      font-size: 13px;
      font-weight: 600;
      color: #bfc3c6;
      margin: 18px 0 0 0;
    }
    h5{
      font-size: 10px;
      font-weight: 600;
      color: #bfc3c6;
      margin: 5px 0 0 0;

    }
  }
}
.High_Lighted{
  font-weight: bold;
  color: black;
  background: #fff !important;
  padding: 0 !important;
}