.service-fee-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 8px 0px 8px;
  height: 144px;
  background: #FFFFFF;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.title-price-row {
  width: 100%;
  height: 80px;
}
.service-fee-text{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6.5px;
  h4 {
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    color: $charcoal;
  }
  .service-fee-price {
    display: flex;
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    .line-through {
      color: $spanish-gray;
      text-decoration: line-through;
      padding-right: 12px;
      font-size: 24px;
    }
  }
}
.discount-text {
  display: flex;
  width: 100%;
  span, h6 {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $spanish-gray;
  }
  h6 {
    color: $charcoal;
    background-color: $grainsboro;
    line-height: 15px;
    letter-spacing: 0.02em;
    margin: 0 2px;
  }
  .discount-description {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $spanish-gray;
      margin: 0;
    }
  }
}
.remove-discount-code {
  display: flex;
  width: 100%;
  height: 38px;
  margin-top: 4px;
  align-items: center;
  justify-content: center;
  border-top: 0.3px dashed $grainsboro;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.remove-color {
  color: $bittersweet;
}
.enter-color {
  color: $bleu-de-france;
}