.nns-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .nns-title {
    @extend .subtitle2;
    color: $charcoal;
    font-size: 16px;
    padding: 8px;
  }
  img {
    height: 100%;
  }
  .get-app {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    button {
      text-transform: none !important;
      color: $doctor;
      background-color: $bleu-de-france;
      outline: none;
      box-shadow: none;
      border: 1px solid $bleu-de-france;
      border-radius: 5px;
      padding: 8px;
      width: 100%;
      @extend .button;
      &:hover, &:focus, &:active {
        color: $doctor;
        background-color: $bleu-de-france;
      }
    }
  }
  .get-help {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
    span {
      padding-left: 8px;
      color: $bleu-de-france;
      @extend .button;
    }
  }
}