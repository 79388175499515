.booking-info-container {
  .booking-service {
    padding: 20px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1;
    background: $doctor;
    border-radius: 20px 20px 0 0;
    .booking-service-wrapper {
      background: transparent;
      padding: 0;
      .booking-service-product-image img {
        margin-left: 0;
      }
      .booking-service-name {
        @extend .h6;
        margin-bottom: 0;
      }
      .booking-service-description {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $spanish-gray;
        
      }
    }
  }
  .info-icon {
    display: none;
  }
  .booking-service-body {
    padding: 104px 0 90px;
    background: $doctor;
    .got-it-button {
      position: fixed;
      bottom: 0;
      padding: 16px;
      background-color: $doctor;
      width: 100%;
      .button-container {
        &.MuiButtonBase-root.MuiButton-root {
          width: 100%;
        }
      }
    }
  }
  + .close-button-container {
    position: fixed;
    bottom: 0;
    padding: 20px;
    background: $doctor;
    width: 100%;
    button {
      @extend .button;
      background: $bleu-de-france;
      border-radius: 5px;
      color: $doctor;
      text-transform: none;
      width: 100%;
      border: 0;
      padding: 8px 16px;
    }
  }
}