.Billing-error-modal .Modal {
    display: block;
    background-color: rgba(110, 110, 110, 0.55);
}
.Billing-error-modal .Modal .modal-dialog {
    margin: 220px 45px
}
.Billing-error-modal .Modal .modal-content {
    padding: 10px;
    border-radius: 15px;
}
.Billing-error-modal .Modal .modal-kuya-container {
    position: relative;
    text-align: center;
    margin: auto;
    margin-top: -75px;
}
.Billing-error-modal .Modal .modal-kuya-container img{
    width: 100px;
    margin: auto;
    margin-top: 0px;
}
.Billing-error-modal .Job-locations{
    white-space: nowrap;
    overflow: hidden;
}
.Billing-error-modal .text-display{
    color: black;
    font-weight: bold;
}
.Billing-error-modal .text-title{
    color: #949494;
}
.Billing-error-modal hr{
    margin-top: 5px;
    margin-bottom: 5px;
}
.Billing-error-modal .Billing-error-modal-title p{
    font-size: 16px;
    margin: 0;
}
.Billing-error-modal .message{
    padding: 0 15px;
}
.Billing-error-modal .Billing-error-modal-button-container{
    padding: 0px 10px 10px 10px;
}
.Billing-error-modal .retry-button{
    text-transform: none !important;
    background-color: #0488FA !important;
    color: white !important;
    outline: none;
    box-shadow: none;
    border: none;
    width: 100%;
    border-radius: 3px !important;
    padding: 6px !important;
    font-size: 12px !important;
    /* font-family: 'lato_black'; */
}
.Billing-error-modal .cancel-button{
    text-transform: none !important;
    background-color: #0488FA !important;
    color: white !important;
    outline: none;
    box-shadow: none;
    border: none;
    width: 100%;
    border-radius: 3px !important;
    padding: 6px !important;
    font-size: 12px !important;
    /* font-family: 'lato_black'; */
}