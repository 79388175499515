.job-matched-lottie {
  z-index: 101;
  position: relative;

  div {
    position: fixed;
    max-width: 412px;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}
.top-icon {
    display: flex;
    justify-content: center;
    align-self: center;
    padding-top: 30px;
}
.job-matched-bottom-sheet {
    flex-direction: column;
    padding: 0 0 20px;
    background-color: $doctor;
}
.get-started-button {
    .button-container.MuiButtonBase-root.MuiButton-root {
        width: 100%;
        padding: 8px;
    }
}