@import "./BookingInfoModal/BookingInfoModal";
@import "./BookingService/BookingService";
@import "./BookingLocationBox/BookingLocationBox";
@import "./BookingHeader/BookingHeader";
@import "./BookingServiceDescription/BookingServiceDescription";
@import "./BookingNote/BookingNote";
@import "./ReviewBookingButton/ReviewBookingButton";
@import "./BookingSpecial/BookingSpecial";
@import "./ReviewBooking/ReviewBooking";
@import "./JobLocations/JobLocations";
@import "./BookingPayment/BookingPayment";
@import "./BookingButton/BookingButton";
@import "./BookingOverlaidButton/BookingOverlaidButton";
@import "./BookingPriceErrorModal/BookingPriceErrorModal";
@import "./RejectedPaymentMethod/RejectedPaymentMethod";
@import "./HowItWorks/HowItWorks";
@import "./HowItWorksCarousel/HowItWorksCarousel";
@import "./ServiceFee/ServiceFee";
@import "./DeliveringTo/DeliveringTo";
@import "./PaymentMethod/PaymentMethod";
@import "./PromoCodeBottomSheet/PromoCodeBottomSheet";
@import "./BookingPriceTime/BookingPriceTime";
@import "./MymallReviewBooking/MymallReviewBooking";
@import "./MymallProductInfo/MymallProductInfo";

.booking-inner {
    overflow-y: scroll;
    padding-bottom: 80px;
    width: 100%;
    display: inline;
    .booking-skeleton {
        margin-top: 80px;
        padding: 0 20px;
    }
}
.call-to-action-container {
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 16px 20px;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    @media (min-width: 770px) {
        max-width: 412px;
    }
    .button-container {
        width: fit-content;
    }
    .button-container.MuiButtonBase-root.MuiButton-root {
        padding: 8px 32px;
    }
}
.booking,
.booking-note,
.review-container {
    .react-swipeable-view-container {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    }
    .Sheet_Content  {
        width: 100%;
    }
    .ReactSwipeableBottomSheet--open  , .ReactSwipeableBottomSheet--closed{
        border-radius: 20px 20px 0 0 !important;
        background-color: $doctor !important;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            width: 0;
        }
    }
}
.product-info-bottom-sheet {
    background-color: $doctor;
    border-radius: 20px 20px 0 0;
}
.choose-location-bottom-sheet {
    // height: 450px;
    background-color: $doctor;
    border-radius: 20px 20px 0 0;
}
.choose-location-bottom-sheet {
    .indicator-container {
        position: fixed;
        border-radius: 20px 20px 0 0;
        z-index: 2;
    }
    .location-bottom-sheet-container {
        position: relative;
        overflow-y: auto;
        position: relative;
        border-radius: 20px 20px 0 0;
        // display: inline;
        &::-webkit-scrollbar {
            width: 0;
        }
        .location-search-suggestions {
            margin-top: 0;
        }
    }
    .title-bar-container {
        top: 10px;
    }
}
.next-button-loading {
    width: 41px;
    margin: 0 auto;
    display: flex;
    .white-fading-circle-loading,
    .dark-fading-circle-loading {
        width: 27px;
        height: 27px;
    }
}