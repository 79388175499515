.transfer-confirmation-container {
  .transfer-confirmation-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-weight: 600;
      font-size: 19px;
      line-height: 28px;
      letter-spacing: 0.15px;
      color: $charcoal;
      margin: 0;
    }

    .subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.5px;
      color: $spanish-gray;
      margin: 4px;

      p {
        margin: 0;
        padding-left: 5px;
        color: $charcoal;
      }
    }
  }

  .transfer-confirmation-body {
    margin-top: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 8px;
    height: 128px;
    background: #F3F5F6;
    border-radius: 8px;

    & > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: $charcoal;
      .text-bold {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
      }
    }
  }

  .transfer-confirmation-splitter {
    height: 0;
    border: 1px dashed #DBDBDB;
    width: 100%;
  }

  .description-about-fee {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: $spanish-gray;
    margin: 16px 0;
  }

  .current-payment-methods-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 0px;
    width: 100%;
    height: 78px;
    background: #F3F5F6;
    border-radius: 8px;
    margin-top: 16px;

    .current-payment {
      flex: 1 1 50%;
      max-width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon {
        height: 16px;
        width: 16px;
        img {
          max-width: 25px;
        }
      }
      p {
        color: $spanish-gray;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.4px;
        margin: 5px 0;
      }
      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: $charcoal;
      }
    }
  }

  .transfer-confirmation-footer {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: $spanish-gray;
    margin: 16px 0;
  }
}