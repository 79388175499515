#QRContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 100%;
  img {
    width: 150px;
    margin-bottom: 8px;
  }
  .QR-text {
    display: flex;
    flex-direction: column;
    h2 {
      align-self: center;
      font-weight: bold;
      margin-top: 12px;
      font-size: 25px;
    }
    h3 {
      margin-top: 4px;
      font-size: 16px;
      font-weight: bold;
    }
    p {
      font-size: 12px;
    }
  }
}
.download-qr-code-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 18px;
}