.saved-places {
    background-color: $doctor;
    .saved-places-favorite {
        display: flex;
        flex-direction: column; 
        width: 100%;
        background-color: white;
        border-radius: 8px;
        .saved-places-favorites-title {
            padding: 10px 15px;
            p {
                color: #676767;
                margin: 0;
            }
        }
    }
    .saved-places-favorite-item {
        padding: 16px 0px 16px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .title,
        .add-title {
            display: flex;
            align-items: center;
            .sub-title {
                align-items: center;
            }
            
        }
        .divider {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .saved-places-divider {
        border-bottom: 0.3px dashed $grainsboro;
        margin: 0 8px;
    }
}