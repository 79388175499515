.Job_Rating_Thumb img{
    /* padding-top: 22px; */
}
.Job_Rating_Thumb img.up{
    width: 48px;
    height: 48px;
    margin: auto;

}
.Job_Rating_Thumb img.down{
    width: 48px;
    height: 48px;
    margin: auto;
}
.Job_Rating_Thumb_Selected {
    background-color: #0C87FA !important;
}
.Job_Rating{
    .Prevent_Touch{
        transform: translate(0px, -100%) !important;
    }
}