
.location-box .pin-icon-wrapper {
    position: relative;
}
.location-box .pin-icon-wrapper span {
    color: #DF5D4D;
    font-size: 8px;
    font-weight: bold;
    position: absolute;
    left: 50%;
    top: 3px;
    transform: translateX(-50%);
}
.booking-location-box .Location-box.draggable {
    justify-content: space-between;
}