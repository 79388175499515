.request-placed {
    .title-bar-container {
        .title-bar-icon {
            display: flex;
            .heading {
                padding: 0 8px 0 0;
                @extend .subtitle1;
                text-align: left;
            }
            .lozenge-container{
                align-items: center;
                .lozenge-inner {
                    height: fit-content;
                    .lozenge-title {
                        p {
                            @extend .overline;
                        }
                    }
                }
            }
        }
    }
    .matching-process {
        .matching-process-title {
            padding: 0;
        }
        .matching-process-container {
            padding: 10px 20px 100px 16px;
            .matching-process-row {
                &.job-state {
                    padding-left: 2px;
                    .icon {
                        width: 9px;
                    }
                    p {
                        padding-left: 20px;
                    }
                }
                &.job-date {
                    padding-left: 2px;
                }
            }
            .request-placed-confirm-button {
                position: fixed;
                width: 100%;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                padding: 20px;
                background-color: $doctor;
                z-index: 1;
                @media (min-width: 770px) {
                    max-width: 412px;
                }
                button {
                    width: 100%;
                }
            }
        }
    }
}
.request-placed-image {
    position: relative;
    padding-top: 65px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

}
@media(max-width: 400px) {
    .request-placed .matching-process .matching-process-container {
        padding: 0;
    }
    .request-placed {
        .request-placed-image {
            display: none;
        }
        .matching-process {
            padding-top: 100px;
            .matching-process-title {
                margin-bottom: 15px;
            }
        }
    }
}
@media(min-height: 400px) {
    .request-placed {
        .request-placed-image {
            display: none;
        }
        .matching-process {
            padding-top: 100px;
            .matching-process-title {
                margin-bottom: 15px;
            }
        }
    }
}