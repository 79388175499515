.assistive {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.4px;
}
.link {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
}
.overline {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
}
.caption {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
}
.button {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}
.body2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
}
.body1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
}
.subtitle2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
}
.subtitle1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
}
.h6 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
}
.h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 34px;
}
.h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 33px;
    line-height: 49px;
}
@mixin  clientV5Shadow(){
    -webkit-box-shadow: 0px 8px 12px rgba(24, 26, 30, 0.12);
    -moz-box-shadow:0px 8px 12px rgba(24, 26, 30, 0.12);
    box-shadow: 0 3px 5px 0 rgba(38, 62, 174, 0.1);
}