.N_B_B_Row{
  padding: 24px 16px;
  display: flex;
  border-bottom: 1px solid #FAFBFC;
  &:last-child {
    border-bottom: 0;
  }
  .N_B_B_R_Icon{
    flex: 1 1 15%;
    width: 15%;
    img{
      margin: 8px 16px;
      width: 25px;
      height: 25px;
    }
  }
  .N_B_B_R_Text{
    color: #FAFBFC;
    flex: 1 1 85%;
    width: 85%;
    h5{
      margin: 4px 0;
      text-align: left;
    }
    h5:nth-child(1){
      font-size: 13px;
      font-weight: 600;
    }
    h5:nth-child(2){
      font-size: 12px;
    }
  }

}