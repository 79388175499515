.job-closed-buttons {
    margin: 8px 0;
    display: flex;
    .retry-button {
        flex-basis: 60%;
        text-align: center;
        color: $doctor;
        background-color: $bleu-de-france;
        @extend .button;
        text-transform: none;
        &:focus, &:active, &:hover {
            color: $doctor;
            background-color: $bleu-de-france;
        }
    }
    .cancel-button {
        flex-basis: 100%;
        border: 1px solid $bittersweet;
        color: $bittersweet;
        @extend .button;
        text-transform: none;
        height: 45px;
    }
}
.unfulfilled-schedule-booking-button {
    width: 100%;
    &.MuiButtonBase-root.MuiButton-root {
        width: 100%;
    }
    p {
        margin: 0;
    }
    svg {
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }
    margin-top: 12px;
}
.job-out-of-working {
    .booking-button-icon img {
        width: 44px;
        height: 44px;
    }
    .matching-process-title {
        padding-bottom: 60px;
    }
}