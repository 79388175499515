.Job_Chat_History_Detail_Sheet_Content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 24px;
  .Title_Box {
    flex: 1 1 100%;
    .Title {
      text-align: center;
      font-size: 15px;
      padding: 0 15px;
      margin: 24px 0;
      color: #3B424A;
    }
  }
  .J_C_H_D_S_C_Job_Type_Box {
    border-bottom: 1px solid $grainsboro;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 4px;
    .Job_Img_Inner {
      
    }
    .Job_Description_Inner {
      padding-left: 16px;
      color: $charcoal;
      @extend .body2;
      p {
        margin: 0;
      }
    }
  }
  .Job_Chat_History_Detail_Locations {
    width: 100%;
    .Job-locations {
      padding: 12px 4px;
      .review-booking-item-inner {
        svg {
          width: 20px;
          height: 20px;
        }
        p {
          margin-bottom: 0;
          @extend .body2;
          padding-left: 12px;
          max-width: 90%;
        }
      }
    }
  }
  .J_C_H_D_S_C_Job_Time_Content {
    width: 100%;
    .J_C_H_D_S_C_Job_Type_Box {
      border-bottom: 1px solid $grainsboro;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: baseline;
      width: 100%;
      padding: 12px 4px;
    .Job_Description_Inner {
      padding-left: 16px;
      color: $charcoal;
      @extend .body2;
    }
    .job-time-inner {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  }
  .card {
    display: flex;
    width: 100%;
    padding: 12px 4px;
    .billing-card-image.GCash {
      width: 20px;
      height: 15px;
    }
    .job-description-container {
      width: 100%;
      display: flex;
      align-items: center;
      .job-description-inner {
        @extend .body2;
        color: $charcoal;
        margin-bottom: 0;
        padding-left: 16px;
      }
      .billing-card-image.visa {
        width: 24px;
        height: 14px;
        + .job-description-inner {
          padding-left: 12px;
        }
      }
      .billing-card-image.mc {
        width: 22px;
        height: 14px;
      }
      .mykoin-image {
        width: 13px;
        height: 13px;
        padding: 1px;
        margin-left: 5px;
        + p {
            padding-left: 19px;
            margin: 0;
        }
      }
    }
  }
  .button-container {
    margin: 40px 0 20px;
  }
}