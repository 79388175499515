.Location-search-booking-add-more-stop .Header-back .Header-back-img{
    padding: 7px;
    box-shadow: none;
}
.Location-search-booking-add-more-stop .Location-search-box .Location-search-box-input{
    font-size: 16px;
}
.Location-search-booking-add-more-stop .Location-search-box .Start-icon{
    margin-top: 4px;
}
.Location-search-booking-add-more-stop .Location-search-box .Finish-light-icon{
    margin-top: 2px;
}
.Location-search-booking-add-more-stop .Location-search-box .Location-pin-hole-icon{
    width: 18px;
    max-height: 20px;
    /* margin-top: 2px; */
    margin-left: -2px;
}
.Location-search-booking-add-more-stop .Location-search-spacer-booking .Spacer-icon{
    width: 1.5px;
    margin-top: 2px;
}
.Location-search-booking-add-more-stop .Location-search-box .location-search-box-delete {
    right: 6px;
    padding-top: 4px;
}