.rce-container-mbox {
    &.interactive-box-wrapper  {
        .rce-mbox {
            padding: 0 20px 3px;    
        }
        .voip-box-container {
            .interactive-message-header {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .title {
                    font-weight: 600;
                    font-size: 19px;
                    line-height: 28px;
                    letter-spacing: 0.15px;
                    color: $charcoal;
                    margin: 0;
                }
                .subtitle {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 22px;
                    text-align: center;
                    letter-spacing: 0.5px;
                    color: $spanish-gray;
                    margin: 4px;
                    p {
                        margin: 0;
                        padding-left: 5px;
                        color: $charcoal;
                    }
                }
            }
            .interactive-message-body {
                margin-top: 12px;
                :first-child {
                   margin-bottom: 8px;
                }
                .gcash-input {
                    background: rgba(12, 135, 250, 0.05);
                    border-radius: 8px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 60px;
                    padding: 0 16px;
                    .gcash-info {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        margin-top: 8px;
                        .title {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            letter-spacing: 0.4px;
                            color: $spanish-gray;
                            margin: 0;
                        }
                        .value {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.25px;
                            color: $charcoal;
                            margin: 0;
                        }
                    }
                    .copy-btn {
                        background: none;
                        border: none;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        text-align: right;
                        letter-spacing: 0.25px;
                        color: $bleu-de-france;
                    }
                }
            }
            .interactive-message-action {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 16px;
                .action-splitter {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    .dashed-splitter {
                        border: 1px dashed #DBDBDB;
                        width: 50%;
                        height: 0;
                    }
                    .text {
                        margin: 16px;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 15px;
                        text-align: center;
                        letter-spacing: 0.4px;
                        color: $spanish-gray;
                    }
                }
            }
            .bank-fee-tip {
                margin-top: 8px;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.4px;
                color: $spanish-gray;
            }
        }
  }
}