.matching-process-row {
    .job-locations-container {
        &.review-booking-item-container {
            padding: 0;
            width: 100%;
            .review-booking-item-inner {
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                border: 0;
                margin: 0;
                .icon-location {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}