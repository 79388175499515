.job-receipt-cost{
  font-size: 18px;
  line-height: 29px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 8px;
  color: $charcoal;
  .total-amount {
    @extend .h6;
    margin-bottom: 0;
  }
  .cost {
    @extend .subtitle2;
    margin-bottom: 0;
  }
  .cost-text {
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.25px;
    margin-bottom: 0;
    color: $dim-gray;
  }
}