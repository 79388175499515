.Job-locations {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $grainsboro;
  padding: 12px 0;
  flex-direction: column;
  .review-booking-item-inner {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    p {
      margin-bottom: 0;
      @extend .body2;
      padding-left: 16px;
    }
  }
}