.tabs-menu-tab{
  display: flex;
  flex-basis: 100%;
  min-width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.tabs-menu-tab::-webkit-scrollbar-track
{
  background-color: #0070E0;
  color:#0070E0 ;
  width: 0;
}

.tabs-menu-tab::-webkit-scrollbar
{
  background-color: #0070E0;
  color:#0070E0 ;
  width: 0;
  display: none;
}

.tabs-menu-tab::-webkit-scrollbar-thumb
{
  background-color: #0070E0;
  color:#0070E0;
  width: 0;
}

.tabs-menu-segment{
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  border-radius: 24px;
  margin: 0 16px;
  .item-segment:first-child{
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    border-right: 0;
    .segment-active{
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }
  }
  .item-segment:last-child{
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    border-left: 0;
    .segment-active{
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }
}