.booking-price-error {
    padding: 20px;
    background-color: $doctor;
    .booking-price-error-content {
        display: flex;
        margin-bottom: 24px;
        .booking-price-description {
            padding-left: 16px;
            .title {
                color: $charcoal;
                @extend .h6;
                margin: 0;
            }
            .text-secondary {
                @extend .caption;
                color: $spanish-gray;
                margin: 0;
            }
        }
    }
    .booking-price-error-button {
        .button-container.MuiButtonBase-root.MuiButton-root {
            width: 100%;
            padding: 8px;
        }
    }
}