.logout-btmsheet-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logout-btmsheet-title {
  font-weight: bold;
  font-size: 15px;
  padding-top: 8px;
}
.logout-btmsheet-description {
  color: $spanish-gray;
  font-size: 12px;
  padding-top: 4px;
}
.confirm-buttons-wrapper {
  width: 100%;
  height: 86px;
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;
  font-weight: bold;
}
.dismiss-btn {
  text-transform: none !important;
  background-color: white;
  color: $bleu-de-france;
  border: 1px solid $bleu-de-france;
  margin: 0 8px;
  outline: none;
  box-shadow: none;
  width: 100%;
  border-radius: 5px !important;
}

.confirm-logout-btn {
  text-transform: none !important;
  background-color: $bittersweet;
  color: white;
  border: none;
  margin: 0  8px;
  outline: none;
  box-shadow: none;
  width: 100%;
  border-radius: 5px !important;
}