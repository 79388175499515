.Job_Rating_Completed_Section_1{
  display: flex;
  flex: 1;
  flex-basis: 100%;
  flex-direction: column;
  
  .Job_Rating_S_1_Content{
    margin: 16px 0;
    h3{
      font-size: 19px;
      font-weight: bold;
      color: #3B424A;
      line-height: 28px;
      margin: 0;
    }
    h5{
      color: #898E92;
      font-weight: 400;
      font-size: 15px;
      margin: 5px 0 0;
    }
  }
}
.Job_Rating_Buttons{
  display: flex;
  flex: 1;
  flex-basis: 100%;
  flex-direction: row;
  justify-content: flex-end;
  margin: 24px 0 0;
  .Job_Rating_Buttons_Skipp{
    flex-basis: 35%;
    .J_R_B_Skipp{
      text-transform: none !important;
      width: 100%;
      outline: none;
      box-shadow: none;
      background-color: $doctor;
      color: #0488FA;
      border: none;
      font-weight: 600;
      border-radius: 3px;
      padding: 12px;
      @extend .button;
    }
  }
  .Job_Rating_Buttons_Submit{
    flex-basis: 100%;
    .J_R_B_Submit{
      text-transform: none !important;
      width: 100%;
      outline: none;
      box-shadow: none;
      font-weight: 600;
      background-color: #0488FA;
      color: $doctor;
      border: none;
      border-radius: 5px;
      padding: 12px;
      @extend .button;
      &.Mui-disabled {
        background-color: $aero;
        cursor: not-allowed;
      }
    }
  }

}
.Job_Rating_Bottom_Sheet{
  .Sheet_Content {
    width: 100%;
  }
}