.rejected-payment-container {
  .rejected-payment-header {
    margin-top: 0;
    .h2 {
      color: $bittersweet;
      font-weight: 600;
      font-size: 19px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.15px;
    }
    h6 {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.25px;
      color: $spanish-gray;
      text-align: justify;
    }
  }
  .rejected-payment-bottom .button-outline.MuiButtonBase-root.MuiButton-root {
    margin-top: 8px;
  }

  .rejected-payment-bottom .adyen-checkout__button {
    margin-top: -4px;
    background-color: $bleu-de-france;
    .adyen-checkout__button__text {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.25px;
      color: $doctor;
    }
  }
}