.News-empty .Jobs-empty-svg{
    width: 50%;
}
.News-empty .jobs-empty-memories{
    /* font-family: 'lato_black'; */
    color: #646464;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 0;
}
.News-empty p.Jobs-empty-pro{
    color: #949494;
    margin: 0;
}