@import "./JobMatchingModal/JobMatchingModal";
@import "./JobMatchingRippleEffect/JobMatchingRippleEffect";
@import "./JobLocations/JobLocations";
@import "./JobMatchedModal/JobMatchedModal";
@import "./JobUnfulfilledModal/JobUnfulfilledModal";
@import "./JobOutOfWorkingHourModal/JobOutOfWorkingHourModal";

.job-matching {
    .title-bar-container {
        padding: 20px 0;
        .title-bar-icon {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 12px;
            img {
                flex: 1 1 5%;
                max-width: 5%;
            }
            .heading {
                padding: 0 8px;
                @extend .subtitle1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                // max-width: 80%;
                // flex: 1 1 80%;
                margin: 0;
                text-align: left;
            }
            .lozenge-container{
                align-items: center;
                justify-content: center;
                flex: 1 1 15%;
                max-width: 15%;
                .lozenge-inner {
                    height: fit-content;
                    .lozenge-title {
                        p {
                            @extend .overline;
                        }
                    }
                }
            }
        }
    }
    .matching-cta-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: $doctor;
        h2 {
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            margin: 0 0 20px;
            color: $charcoal;
        }
        .button-container {
            position: relative;
            padding: 0;
            &.MuiButtonBase-root.MuiButton-root {
                width: 100%;
            }
            &.cancel-booking {
                margin-top: 10px;
                &.MuiButtonBase-root.MuiButton-root {
                    background-color: transparent;
                    color: $cinnabar;
                    border: 1px solid $cinnabar;
                }
            }
        }
    }
}