.Loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0b87f9;
    z-index: 10000000;
}

.Loading-screen-img {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    width: 70%;
}

.loading-screen-index {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0070E0;
    z-index: 100000;
}

.loading-screen-index .loading-screen-index-image {
    position: absolute;
    margin: 0 auto;
    top: 20%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 250px;
}
.loading-screen-index .splash-image {
    display: flex;
    height: auto;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    width: 300px;
}
.loading-screen-index .splash-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 0 auto;
    height: 100%;
}