.Location-search-saved-place-box  {
    background-color: white;
    display: inline-block;
    padding: 10px 17px;
    border-radius: 25px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    white-space:nowrap;
    overflow: hidden;
    margin-right: 10px;
}
.Location-search-saved-place-box svg {
    float: left;
    max-width: 15px;
    max-height: 15px;
    margin-top: 2px;
}
.Location-search-saved-place-box p {
    margin-left: 8px;
    float: left;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    color: #000;
}