.location-search-box {
    display: flex;
    align-items: center;
    &.saved-places-location-search,
    &.search-location-container {
        padding: 75px 20px 0;
    }
    .form-group-container {
        width: 100%;
        border: 1px solid $silver-chalice;
        border-radius: 8px;
        padding: 5px 8px;
        &.focused {
            border-color: $aero;
        }
        input {
            width: 100%;
            border-radius: 0;
            &:focus {
                outline: none;
            }
        }
        .icon {
            path {
                stroke: $grainsboro;
            }
        }
    }
    .location-search-box-inner {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
}