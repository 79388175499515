.account-container {
  display: block;
  position: relative;
  height: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
  &.z-index {
    z-index: 1;
  }
  .account-profile {
    margin: 65px 20px 0px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    background: rgba(12, 135, 250, 0.05);
    border-radius: 8px;
    border-top-right-radius: 8px;
    .account-profile-name {
      @extend .h6;
      margin-bottom: 0;
    }
    .account-profile-edit {
      display: inline-block;
      margin: 0 5px 0 0;
      color: $bleu-de-france;
      font-size: 12px;
      line-height: 18px;
    }
    .avatar-wrapper {
      padding-right: 15px;
    }
    .profile-info {
      a {
        @extend .caption;
      }
    }
    .account-profile-forward {
        width: 6px;
        height: 10px;
    }
  }
}