.button-container {
  z-index: 100;
  width: 100%;
  display: inline-grid;
  &.MuiButtonBase-root {
    &.MuiButton-root {
      &.MuiButton-contained {
        box-shadow: none;
        @extend .button;
        background: $bleu-de-france;
        border-radius: 5px;
        color: $doctor;
        text-transform: none;
        padding: 8px;
        border: 1px solid $bleu-de-france;
        .MuiTouchRipple-root {
          width: 100%;
        }
        &.Mui-disabled {
          background-color: $alice-blue;
          color: $aero;
          border-color: $alice-blue;
        }

        &:hover {
          @extend .subtle-black-shadow;
        }
      }
    }
  }
}
.button-outline {
  &.MuiButtonBase-root {
    &.MuiButton-root {
      @extend .button;
      border-color: $bleu-de-france;
      border-radius: 5px;
      color: $bleu-de-france;
      background-color: $doctor;
      border: 1px solid $bleu-de-france;
      padding: 8px;
      text-transform: inherit;
      
      &.Mui-disabled {
        background-color: $alice-blue;
        color: $aero;
      }

      &:hover {
        @extend .subtle-black-shadow;
      }
    }
  }
}
.request-again{
  width: 100%;
  .MuiButtonBase-root{
    width: 100%;
    padding: 14px !important;
    .MuiButton-label{
      font-size: 18px !important;
    }
  }
}
.no-border {
  border: none !important;
}