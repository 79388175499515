.Job-receipt-row hr{
    margin: 0px;
    position: absolute;
    left: 0;
    right: 0;
    top: 15px;
    overflow: hidden;
    border-top: 2px dotted #eeeeee;
}
.Job-receipt-row .Job-receipt-title{
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.25px;
    color: $dim-gray;
}
.Job-receipt-row .Job-receipt-value{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: $charcoal;
    padding-left: 16px;
}
.Job-receipt-row{
    width: 100%;
    height: auto;
    min-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.service-name {
        justify-content: flex-start;
        .Job-receipt-title {
            color: $charcoal;
            font-weight: 600;
        }
        .Job-receipt-value {
            padding-left: 0;
        }
    }
    &.location-job-receipt {
        flex-direction: column;
        align-items: flex-start;
        .Job-receipt-value {
            max-width: 100%;
            padding-left: 0;
        }
        .location-job-receipt-inner {
            display: flex;
            align-items: flex-start;
            .Dynamic_Parent_Icon {
                width: 24px;
                height: 24px;
                img {
                   width: 24px;
                    height: 24px;
                    max-width: fit-content;
                }
            }
            .Job_Receipt_Location_Address {
                font-weight: normal;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: 0.25px;
                margin-top: 1px;
            }
        }
    }
}