.booking-service {
  padding: 65px 20px 0;
  display: flex;
  align-items: center;

  .booking-service-wrapper {
    flex: 1 1 100%;
    max-width: 100%;
    display: flex;
    align-items: center;

    .booking-service-product-image img {
      width: 48px;
      height: 48px;
    }
  }

  .booking-service-product {
    flex-basis: 80%;
    max-width: 80%;
    padding: 0 12px;
    .fader {
      &.fader-left {
        background: linear-gradient(
          to left,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1)
          );
      }
      &.fader-right {
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1)
          );
      }
    }
    .long-heading {
      width: 100%;
    }
    p {
      margin: 0;
    }
    &.has-padding {
      padding: 0 12px 0 0;
      span {
        padding: 0 12px;
      }
      .booking-service-description {
        padding: 0 12px;
      }
    }
    .booking-service-name {
      @extend .h6;
      color: $charcoal;
      margin-bottom: 0;
    }

    .booking-service-description {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: $spanish-gray;
      
    }
  }

  .info-icon {
    position: absolute;
    right: 20px;
  }
}
.prgress-bar {
  padding: 0 20px;
}