.optional { /* Microsoft Edge */
    color: #CBCBCB;
    /* font-family: 'lato_regular'; */
}
.sticky-button {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 15px;
    background-color: #f9fafc;
    right: 0;
    left: 0;
}
.sticky-button-container {
    float: none;
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: 0;
    margin: 0;
}