
.loading-screen {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(24, 26, 30, .7);
	z-index: 1051;
	margin: 0 auto;
}
.lds-default {
	display: inline-block;
	position: absolute;
	width: 67px;
	height: 67px;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #fff;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
		&:nth-child(1) {
			animation-delay: 0s;
			// top: 37px;
			// left: 66px;
			top: 30px;
			left: 59px;
		}
		&:nth-child(2) {
			animation-delay: -0.1s;
			// top: 22px;
			// left: 62px;
			top: 15px;
			left: 55px;
		}
		&:nth-child(3) {
			animation-delay: -0.2s;
			// top: 11px;
			// left: 52px;
			top: 4px;
			left: 45px;
		}
		&:nth-child(4) {
			animation-delay: -0.3s;
			// top: 7px;
			// left: 37px;
			top: 0;
			left: 30px;
		}
		&:nth-child(5) {
			animation-delay: -0.4s;
			// top: 11px;
			// left: 22px;
			top: 4px;
			left: 15px;
		}
		&:nth-child(6) {
			animation-delay: -0.5s;
			// top: 22px;
			// left: 11px;
			top: 15px;
			left: 4px;
		}
		&:nth-child(7) {
			animation-delay: -0.6s;
			// top: 37px;
			// left: 7px;
			top: 30px;
			left: 0px;
		}
		&:nth-child(8) {
			animation-delay: -0.7s;
			// top: 52px;
			// left: 11px;
			top: 45px;
			left: 4px;
		}
		&:nth-child(9) {
			animation-delay: -0.8s;
			// top: 62px;
			// left: 22px;
			top: 55px;
			left: 15px;
		}
		&:nth-child(10) {
			animation-delay: -0.9s;
			// top: 66px;
			// left: 37px;
			top: 59px;
			left: 30px;
		}
		&:nth-child(11) {
			animation-delay: -1s;
			// top: 62px;
			// left: 52px;
			top: 55px;
			left: 45px;
		}
		&:nth-child(12) {
			animation-delay: -1.1s;
			// top: 52px;
			// left: 62px;
			top: 45px;
			left: 55px;
		}
	}
}
@media (min-width: 770px) {
	.loading-screen {
		max-width: 414px;
	}
}

@keyframes lds-default {
	0%,20%,80%,100% {
		transform: scale(1);
	}
	50% {
		transform: scale(2.2);
	}
}
