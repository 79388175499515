@import "./LocationSearchBox/LocationSearchBox";
@import "./LocationSearchDeleteButton/LocationSearchDeleteButton";
@import "./LocationSearchSuggestions/LocationSearchSuggestions";
@import "./LocationBookmarked/LocationBookmarked";
@import "./LocationSearchSaving/LocationSearchSaving";
@import "./GoogleMap/GoogleMap";
@import "./LocationSearchMap/LocationSearchMap";
@import "./LocationSearchCurrent/LocationSearchCurrent";
@import "./LocationSearchSet/LocationSearchSet";
@import "./LocationSearchConfirm/LocationSearchConfirm";
@import "./LocationSearchBottomSheet/LocationSearchBottomSheet";
@import "./LocationSearchBoxDraggable/LocationSearchBoxDraggable";
.location-search {
    &.saved-places {
        .saved-places-favorite {
            padding-top: 32px;
        }
    }
}
.location-search-confirm {
    button {
        width: 100%;
    }
}
.change-location {
    .location-search-suggestions {
        padding-bottom: 80px;
    }
    .location-bookmarked-container {
        padding-bottom: 0;
    }
    .location-search-map-confirm {
        position: fixed;
        margin: 0 auto;
        max-width: 412px;
    }
    .skeleton-saved-places {
        padding: 20px;
    }
}