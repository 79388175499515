.News{
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 200px;
  overflow-y: scroll;
}
.News::-webkit-scrollbar {
  width: 0
}
.skeleton-news {
  padding: 0 20px;
}