.Home_Sub_Categories{
  display: flex;
  width: 100%;
}
.Sub_Category_Parent{
  display: flex;
  flex: 1 1 100%;
  margin: 68px 16px 0  16px;
  height: 40px;
  .H_S_P_Text{
    h3{
      font-size: 19px;
      margin: 16px 10px;
    }
  }
  .H_S_P_Icon{
    img{
      width: 48px;
      height: 48px;
    }
  }
}
.Sub_Parent_Product{
  margin: 16px 0 0 0 !important;
}