.advance-button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(12, 135, 250, 0.05);
  border-radius: 8px;
  padding: 8px 16px 8px 8px;
  .advance-button-action {
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    letter-spacing: 0.25px;
    color: $bleu-de-france;
  }
  .advance-button-specs {
    display: flex;
    align-items: center;
    .advance-button-text {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      .advance-button-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.4px;
        color: $spanish-gray;
      }
      .advance-button-subtitle {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: $charcoal;
      }
    }
  }
}