.rce-container-mbox {
    &.interactive-box-wrapper  {
        .rce-mbox {
            padding: 0 20px 3px;    
        }
        .voip-box-container {
            .top-icon {
                padding: 0 0 8px;
            }
            .subtitle {
                &.has-pre-subtitle {
                    flex-direction: column;
                }
            }
            .call-to-actions {
                .button-container {
                    &.reject-send-mk {
                        &.button-outline.MuiButtonBase-root.MuiButton-root {
                            color: $cinnabar;
                            border-color: $cinnabar;
                        }
                    }
                    &.accept-send-mk {
                        &.MuiButtonBase-root.MuiButton-root {
                            background-color: $bleu-de-france;
                            border: 1px solid $bleu-de-france;
                            padding: 8px;
                        }
                    }
                }
            }
            .view-note {
                display: flex;
                justify-content: center;
                margin-bottom: 16px;
                &.button-container.MuiButtonBase-root.MuiButton-root {
                    background: transparent;
                    color: $bleu-de-france;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 19px;
                    padding: 3px;
                    border: 0;
                }
            }
        }
  }
}