.tab-item-history{
  margin: 0 12px !important;
  &:first-child{
    margin-left:24px !important; ;
  }
  h5{
    color: #81B7E9;
    font-size: 19px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.15px;
    margin: 16px 0 24px;
    white-space: pre;
  }
}
.item-segment{
  flex-basis: 33.333%;
  color:#0070E0;
  background: #fff;
  border: 1px solid #0070E0;
  text-align: center;
  height: 26px;
  h5{
    margin: 5px;
    font-size: 12px;
  }
}
.tab-active{
  h5{
    color: #0057AD!important;
  }
}
.segment-active{
  background: #0070E0 !important;
  color: #fff;
  font-weight: bold;
  height: 24px;
  margin: 0;
  overflow: auto;
}