.Avatar {
    /* background-color: blue; */
    width: 60px;
    height: 60px;
}
.Avatar img{
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}