.profile-form {
    padding: 64px 20px 0;
    background-color: $doctor;
    .client-avatar-name {
        display: flex; 
        align-items: center;
        .avatar-container {
            padding-right: 15px;
        }
        .client-name-input {
            flex-basis: 100%;
            display: flex;
            flex-direction: column;
        }
    }
    .client-mobile-number {
        display: flex;
        .select-country {
            flex-basis: 25%;
            max-width: 25%;
            padding-right: 10px;
            .flag {
                display: flex;
                align-items: center;
            }
            select {
                outline: none;
                -webkit-appearance: none;
                text-align: center;
                border: none;
                box-shadow: none;
            }
            select[disabled] {
                background-color: transparent;
                padding: 0;
                opacity: .7;
            }
        }
        .mobile-number {
            flex-basis: 75%;
            max-width: 75%;
            padding-left: 10px;
            .form-control[disabled] {
                background-color: transparent;
                padding: 0;
                opacity: .7;
            }
        }
    }
    .edit-profile-action {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        .button-container.MuiButtonBase-root.MuiButton-root {
            padding: 10px;
            @extend .button;
        }
        button {
            width: 100%;
        }
        .error-button {
            &.MuiButtonBase-root {
                &.MuiButton-root {
                    background-color: transparent;
                    color: $bleu-de-france;
                    margin-top: 8px;
                }
            }
        }
    }
}