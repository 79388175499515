#google-map {
    position: absolute !important;
    left: 0;
    right: 0;
    bottom: 0;
    top: 65px;
}
.google-map-blur {
    filter: blur(2px);
}
.map-loading {
    background-color: $doctor;
    position: absolute;
    padding: 10px;
    border-radius: 8px;
    right: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 60px;
    height: 60px;
    z-index: 3;
}