.location-search-suggestions {
    margin-top: 24px;
    padding: 0 20px 25px;
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    background: $doctor;
    .location-search-suggestion-title {
        @extend .overline;
        color: $spanish-gray;
    }
    
    .location-search-suggestions-item {
        padding: 16px;
        border-bottom: 1px solid $grainsboro;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &.is-searching {
            justify-content: space-between;
        }
        @media (min-width: 400px) {
            padding: 16px;
        }
        .recent-search-result {
            margin-right: 12px;
            width: 24px;
            height: 24px;
            @media (min-width: 400px) {
               margin-right: 16px; 
            }
        }
        .bookmark-icon {

        }
        .location-search-suggestions-title {
            @extend .caption;
            margin-bottom: 0;
            font-size: 12px;
            line-height: 18px;
            margin-right: 8px;
            @media (min-width: 400px) {
               margin-right: 16px; 
               padding-left: 12px;
            }
        }
    }
}