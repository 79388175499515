.accordion-wrapper {
    position: absolute;
    right: 20px;
    .accordion {
        background-color: transparent;
        box-shadow: none;
        border: 0;
        svg path {
            fill: $spanish-gray;
        }
    }
    .accordion-summary {
        padding: 0;
        .MuiAccordionSummary-expandIconWrapper {
            transition: none;
        }
    }
    .accordion-items {
        position: absolute;
        background: white;
        right: 10px;
        border-radius: 5px;
        z-index: 999;
        width: 165px;
        padding: 0 16px;
        box-shadow: 1px 3px 3px #e5e5e5;
        p {
            padding: 12px 0;
            border-bottom: 1px solid $grainsboro;
            cursor: pointer;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            margin: 0;
            &.delete {
                color: $cinnabar;
            }
            &:last-child {
                border-bottom: 0;
            }
        }
    }
}