.Not-found .Not-found-svg {
    text-align: center;
    margin-top: 90px;
}
.Not-found .Not-found-svg svg{
    width: 50%;
}
.Not-found .Not-found-oops{
    text-align: center;
    margin-top: 20px;
}
.Not-found .Not-found-wrong p{
    margin-bottom: 0px;
    text-align: center;
    color: #949494;
}
.Not-found .Not-found-button button{
    text-transform: none !important;
    outline: none;
    box-shadow: none;
    background-color: #0488FA !important;
    color: white;
    border: none;
    padding: 8px 50px;
    border-radius: 5px;
    /* font-family: 'lato_black'; */
    font-size: 14px;
}
