.Job_Chat_History_Thumbs_Up {
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0;
  min-width: unset;
  width: auto;
  height: auto;
  img {
    width: 32px;
    height: 32px;
  }
}