.browser-not-supported-container {
    h1 {
        color: $charcoal;
        margin: 0 0 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        padding: 20px 20px 0;
    }
    h2 {
        @extend .subtitle1;
        color: $spanish-gray;
        padding: 0 20px;
        margin: 10px 0;
    }
    .browser-not-supported-image {
        padding: 50px 0 70px;
        margin-bottom: 8px;
        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
    .card {
        margin: 0 16px 24px;
        border-radius: 16px;
        padding: 10px;
        display: flex;
        align-items: center;
        &.card-blue {
            background-color: #EDF6FF;
        }
        h5 {
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: $spanish-gray;
            margin: 0;
        }
        h6 {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: $spanish-gray;
            margin-top: 0
        }
        .link {
            @extend .link;
            color: $bleu-de-france;
            margin: 16px 0 0;
            cursor: pointer;
        }
        .card-image {
            width: 60px;
            height: 60px;
            margin-right: 20px;
            img {
                height: auto;
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }
}

@media(max-height: 730px) {
    .browser-not-supported-container {
        h2 {
            margin-bottom: 12px;
        }
        .browser-not-supported-image {
            width: 85%;
            margin: 0 auto;
        }
        .welcome-call-to-actions {
            padding-top: 0;
        }
    }
}
@media(max-height: 692px) {
    .browser-not-supported-container {
        h2 {
            margin-bottom: 8px;
        }
        .browser-not-supported-image {
            width: 70%;
            margin: 0 auto;
        }
    }
}
@media(max-height: 655px) {
    .browser-not-supported-container {
        h2 {
            margin-bottom: 0px;
        }
        .browser-not-supported-image {
            width: 68%;
            margin: 0 auto;
        }
    }
}