.schedule-container {
  padding: 16px 24px;
  .schedule-header {
    .schedule-title {
      font-weight: 600;
      font-size: 19px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.15px;
      color: $charcoal;
    }
    .schedule-subtitle {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.25px;
      color: $spanish-gray;
    }
  }
  .schedule-date-btn {
    margin: 24px 0;
    height: auto;
    .advance-button-container {
      margin-bottom: 8px;
    }
  }
  .schedule-action-btn {
    height: auto;
    .confirm-and-book {
      margin-bottom: 8px;
    }
  }
}

.datepicker.ios {
  .datepicker-navbar {
    .datepicker-navbar-btn {
      &:last-child {
        color: $bleu-de-france;
      }
    }
  }
  .datepicker-caption-item:last-child {
    visibility: hidden;
  }
  .datepicker-col-1 {
    &:last-child {
      .datepicker-scroll {
        li.disabled {
          visibility: hidden;
        }
      }
    }
  }
}