.Job_Chat_History_Extend_Bottom_Sheet{
  .Title{
    text-align: center;
    font-size: 16px;
    padding: 0 15px;
    margin: 24px 0;
    color: $charcoal;
  }
}
.Job_Chat_History_Extend_Buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;
  .J_C_H_E_B_Left {
    flex: 1 1 100%;
    margin: 0 16px;
    .Retry-Button {
      background-color: $bleu-de-france;
      color: $doctor;
      outline: none;
      box-shadow: none;
      border: none;
      width: 100%;
      border-radius: 5px;
      padding: 8px !important;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-transform: none;
      &:disabled {
        background-color: #C5C5C5 !important;
      }
    }
    .Cancel_Button {
      background: $doctor;
      color: $bleu-de-france;
      outline: none;
      box-shadow: none;
      border: none;
      width: 100%;
      border-radius: 3px;
      padding: 10px !important;
      margin: 10px 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-transform: none;
      &:disabled {
        background-color: #C5C5C5 !important;
      }
    }
  }
}

.Job_Chat_History_Comment {
  flex: 1 1 100%;
  margin: 24px 16px;
  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: $spanish-gray;
  }
}

.Job_Chat_History_Extend {
  .Job_Chat_History_Extend_Minus, .Job_Chat_History_Extend_Plus {
    flex-basis: 50%;
    max-width: 50%;
    background-color: $doctor;
    color: $bleu-de-france;
    font-size: 19px;
    font-weight: bold;
    width: 35px !important;
    height: 48px !important;
    padding: 0;
    outline: none;
    box-shadow: none;
    border: none;
    border-radius: 8px;
    margin: 0 0 0 8px;
    min-width: unset !important;
    @include ca-5-shadow();
    @media (min-width: 400px) {
      width: 48px !important;
      height: 48px !important;
    }
  }
}
