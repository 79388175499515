input {
    border-radius: 8px;
    background-color: $doctor;
    padding: 5px 8px;

    &:focus {
        @extend .subtle-black-shadow;
        border: 1px solid $aero;
        
    }
}
.black-background {
    background: $charcoal;
    width: 90%;
    margin: 0 auto 20px;
    border-radius: 8px;
    .Toastify__close-button--default {
        display: none;
    }
    &.Toastify__toast {
        min-height: auto;
        padding: 15px 24px;
    }
    .Toastify__toast-body {
        &.toastify-inner {
            color: $doctor;
            margin: 0;
            > div {
                display: flex;
                p {
                    margin: 0;
                    color: $doctor;
                    font-size: 14px;
                    flex-basis: 80%;
                }
                button {
                    color: $aero;
                    background: transparent;
                    font-weight: 600;
                    font-size: 12px;
                    border: 0;
                    flex-basis: 20%;
                    text-align: right;
                }
            }
        }
    }
}
.fixed-body {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}