.Home_Location {
  height: 90px;
  background: url('../../../assests/images/home/ServiceCollectionBG.svg') #0057AD  -40px -9px no-repeat;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
  max-width: 100%;
  @media (min-width: 770px) {
    max-width: 412px;
  }
  .free-concierge-container {
    background: $doctor;
    height: 64px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1px 5px 5px;
    position: relative;
    flex: 1 1 20%;
    max-width: 20%;
    .free-icon {
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 8px 0 0 0;
      width: 40px;
      height: 40px;
    }
    .support-icon {
      width: 24px;
      height: 24px;
      margin: 0 auto;
    }
    p {
      margin-bottom: 0;
      color: $dark-orange;
      font-weight: 600;
      text-align: center;
      font-size: 12px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .Home_Location_White_Box{
    flex: 1 1 70%;
    max-width: 70%;
    background: #FAFBFC;
    height: 64px;
    border-radius: 8px;
    
    .H_L_W_B_Top_Box{
      flex: 1 1 100%;
      height: 50%;
      border-bottom: 1px solid #ededed;
      display: flex;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .Dynamic_Parent_Icon{
        max-width: 10%;
        flex: 1 1 10%;
        display: flex;
        justify-content: center;
        .Right_Arrow {
          width: 10px;
          height: 10px;
        }
      }
      .H_L_W_B_Top_Text{
        max-width: 90%;
        flex: 1 1 90%;
        display: flex;
        align-items: center;

        h5 {
          text-align: left;
          margin: 0;
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // font-weight: 600;
          // font-size: 13px;
          // line-height: 19px;
          // color: #3b424a;
          // padding-left: 16px;
        }
      }
    }
    .H_L_W_B_Down_Box{
      flex: 1 1 100%;
      height: 50%;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      align-items: center;
      .Dynamic_Parent_Icon{
        flex: 1 1 12%;
        max-width: 12%;
        display: flex;
        justify-content: center;
        .Location_Search{
          // margin: 8px 0 0 20px;
          width: 16px;
          height: 16px;
        }
      }
      .H_L_W_B_Top_Text{
        max-width: 88%;
        flex: 1 1 88%;
        h5{
          color: #B1B3B7;
          font-size: 12px;
          margin: 0;
        }
      }
    }
  }
  .Location{
    display: flex;
    text-align: center;
    font-size: 15px;
    background-color: rgba(255, 255, 255, 0.48);
    padding: 2px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    align-items: center;
    width: 100%;
    .Location_Pin{
      width: 16px;
      height: 16px;
      // margin: 0 0 0 16px;
    }
    p {
      margin: 0;
    }
    .Pin-icon {
      width: 18px;
      float: left;
    }
  }
}