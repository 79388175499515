.rce-container-mbox {
    &.interactive-box-wrapper  {
        .expired-call {
            text-align: center;
            margin: 0;
            &.rejected {
                color: $rosso-corsa;
                @extend .subtitle2;
            }
            &.accepted {
                color: $emerald;
                @extend .subtitle2;
            }
            &.expired {
                color: $spanish-gray;
                @extend .subtitle2;
            }
        }
        .rce-mbox {
            padding: 0 20px 3px;    
        }
        .voip-box-container {
            background-color: $white-smoke;
            border-radius: 8px;
            padding: 16px;
            &.client {
                float: right;
                margin-left: 50px;
                background-color: $columbia-blue;
                position: relative;
            }
            .title  {
                @extend .h6;
                text-align: center;
                color: $charcoal;
            }
            .subtitle {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 16px 0 12px;
                p {
                    @extend .body2;
                    color: $charcoal;
                    margin: 0;
                }
                .voice-call-lottie {
                    > div {
                        width: 50px !important;
                        height: 50px !important;
                    }
                }
            }
            .call-to-actions {
                display: flex;
                justify-content: space-between;
                .button-container {
                    width: 49%;
                    &.MuiButtonBase-root.MuiButton-root {
                        // width: 100%;
                        border: 1px solid;
                        border-radius: 8px;
                        background-color: $mint;
                        border: 1px solid $mint;
                        padding: 8px;
                    }
                    &.button-outline.MuiButtonBase-root.MuiButton-root {
                        color: $cinnabar;
                        border-color: $cinnabar;
                        background-color: transparent;
                    }
                }
            }
        }
  }
}