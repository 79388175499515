.booking-service {
  padding: 65px 20px 0;
  display: flex;
  align-items: center;

  .booking-service-wrapper {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    background-color: #FAFBFC;
    border-radius: 8px;
    padding: 13px;
    padding-left: 0;
    .booking-service-product-image img {
      width: 32px;
      height: 32px;
      margin-left: 20px;
      margin-right: 8px;
    }
  }

  .booking-service-product {
    flex-basis: 80%;
    max-width: 80%;
    padding: 0 16px;

    .fader {
      &.fader-left {
        background: linear-gradient(
                        to left,
                        rgba(255, 255, 255, 0),
                        rgba(255, 255, 255, 1)
        );
      }
      &.fader-right {
        background: linear-gradient(
                        to right,
                        rgba(255, 255, 255, 0),
                        rgba(255, 255, 255, 1)
        );
      }
    }
    .long-heading,
    .long-subtitle,
    .long-heading-info,
    .long-subtitle-info {
      width: 100%;
    }
    p {
      margin: 0;
    }
    &.has-padding {
      padding: 0 18px 0 0;
      span {
        padding: 0 12px;
      }
      .booking-service-description {
        padding: 0 12px;
      }
    }

    p {
      margin: 0;
    }

    .booking-service-name {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $charcoal;
      margin-bottom: 0;
    }

    .booking-service-description {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.4px;
      color: $spanish-gray;
      padding-right: 4px;
    }
  }

  .info-icon {
    position: absolute;
    right: 38px;
  }
}
.prgress-bar {
  padding: 0 20px;
}