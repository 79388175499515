.location-bottom-sheet-container {
    .booking-skeleton {
        display: flex;
        margin-top: 20px;
    }
    .location-search {
        &.saved-places {
            .saved-places-favorite {
                padding-top: 16px;
            }
        }
    }
    .location-search-saving-container {
        .location-search-saving-item {
            height: 65px;
        }
    }
    + .close-button-container {
        position: fixed;
        bottom: 0;
        padding: 20px;
        background: $doctor;
        width: 100%;
        button {
            @extend .button;
            background: $bleu-de-france;
            border-radius: 5px;
            color: $doctor;
            text-transform: none;
            width: 100%;
            border: 0;
            padding: 8px 16px;
        }
  }
}
.price-location-loading {
    display: flex;
    margin-top: 127px;
}