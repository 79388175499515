.location-search-suggestions {
    &.bookmarked-places {
        margin: 0;
        padding: 10px 20px;
        .location-search-suggestions-item {
            &:last-child {
                border-bottom: 1px solid $grainsboro;
            }
        }
        .location-search-suggestions-item {
            padding: 19px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
