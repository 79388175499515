.Jobs-empty .Jobs-empty-svg{
    width: 60%;
}
.Jobs-empty .jobs-empty-memories{
    /* font-family: 'lato_black'; */
    color: #646464;
    font-size: 18px;
    margin-top: 20px;
}
.Jobs-empty p.Jobs-empty-go-ahead{
    color: #949494;
    margin: 0;
}
.Jobs-empty p.Jobs-empty-pro{
    color: #949494;
    margin: 0;
}
.Jobs-empty p.Jobs-empty-pro span{
    color: #676767;
    /* font-family: 'lato_black'; */
}