.how-it-works-wrapper {
    padding: 0 20px;
    margin-bottom: 24px;
}
.how-it-works-container {
    background-color: $pale-pink;
    padding: 16px 20px 0 20px;
    box-shadow: 0px 2px 4px rgba(24, 26, 30, 0.15);
    border-radius: 16px;
    display: flex;
    .wrapper-image {
        max-width: 40%;
        flex-basis: 40%;
        display: flex;
        align-items: flex-end;
        img {
            max-width: 100%;
            height: auto;
            display: block;
        }
    }
    .content-container {
        max-width: 60%;
        flex-basis: 60%;
        padding: 0 0 16px 16px;
        h6 {
            @extend .subtitle2;
            color: $charcoal;
            margin: 0 0 8px;
        }
        p {
            @extend .body2;
            color: $charcoal;
            margin: 0;
        }
    }
}