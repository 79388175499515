.partner-live-location-header-container {
    .title-bar-container {
        justify-content: flex-start;
        align-items: center;
        padding: 10px 20px;
        .profile {
            display: flex;
            align-items: center;
            padding-left: 16px;
            .profile-img-box {
                .Avatar {
                    width: 50px;
                    height: 50px;
                }
            }
            .profile-text-box {
                // padding-left: 16px;
                .partner-name-container {
                    padding: 5px 0;
                    min-width: 0;
                    white-space: nowrap;
                    position: relative;
                    .heading {
                        padding-left: 12px;
                    }
                }
            }
        }
    }
    .partner-live-location-header {
        padding: 80px 20px 10px;
        background-color: $white-smoke;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .long-heading {
            width: 75%;
            padding: 0;
            &.has-not-fade {
                .heading {
                    padding: 0;
                }
            }
            .heading {
                padding: 0 12px;
            }
        }
        .lozenge-container .lozenge-inner {
            height: fit-content;
        }
        p {
            margin-bottom: 0;
            color: $dim-gray;
            @extend .caption;
        }
    }
    .partner-live-location-padding {
        padding: 56px 20px 10px;
    }
}
.partner-live-location-wrapper {
    .job-chat-history-live-location {
        top: 110px;
        height: 100%;
        .job-chat-history-live-location-map {
            &#google-map {
                margin: 110px auto 0;
                position: fixed !important;
                @media (min-width: 770px) {
                    max-width: 412px;
                }
            }
        }
    }
    .live-location-top {
        top: 86px
    }
}
.live-location-loading-full-width {
    padding-top: 110px;
    height: 100%;
    position: absolute;
    width: 100%;
    .spinner-box {
        padding-top: 65px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
    }
}