.Job_Chat_History_Extend {
  background-color: $doctor;
  padding: 0 20px;
  display: flex;
  margin: 0 0 32px;
  align-items: flex-end;
  .J_C_H_E_Time_Box{
    flex: 1 1 45%;
    max-width: 45%;
    border-bottom: 1px solid $grainsboro;
    padding: 0 8px 16px;
    h5{
      margin: 0;
      color: #3B424A;
      font-weight: 600;
      font-size: 19px;
      line-height: 28px;
    }
  }
  .J_C_H_E_Time_Fee{
    flex: 1 1 20%;
    max-width: 20%;
    padding: 16px 0 16px 8px;
    p{
      font-size: 13px;
      color: #898E92;
      margin: 0;
    }

  }
  .J_C_H_E_Time_Extend_Cost{
    flex: 1 1 35%;
    max-width: 35%;
    display: flex;
    flex-direction: row-reverse;
  }
}

.Chat_Extend_Job{
  // height: 376px;
  background: $doctor;
  .Dynamic_Parent_Icon {
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

