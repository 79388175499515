.stepper-container {
  display: flex;
  max-width: fit-content;
  button {
    background-color: $doctor;
    @extend .big-black-shadow;
    width: 48px;
    height: 48px;
    min-width: auto;
    border-radius: 8px;
    margin: 4px;
    font-size: 20px;
    color: $bleu-de-france;
    &.MuiButton-root.Mui-disabled {
      opacity: 0.5;
      color: $bleu-de-france;
    }
  }
}