.job-chat-history-live-location {
	// z-index: 1000;
	position: absolute;
	width: 100%;
	top: 65px;
    height: 150px;
    .view-full-screen {
        position: absolute;
        bottom: 12px;
        right: 5px;
        background-color: $doctor;
        border-radius: 8px;
        border: 0;
        color: $bleu-de-france;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        padding: 4px 6px;
        filter: drop-shadow(0px 2px 4px rgba(24, 26, 30, 0.15));
    }
	#google-map {
        top: 112px;
        width: 100%;
        position: fixed !important;
        .gm-style-cc {
            // display: none;
        }
	}
	.job-chat-history-live-location-map {
        &#google-map {
            position: absolute !important;
            top: 0;
        }
		.gm-style {
			img {
				max-width: 100% !important;
				height: auto !important;
				display: block;
			}
            .gm-style-iw-c,
            .gm-style-iw-t::after {
                display: none;
            }
		}
	}
}