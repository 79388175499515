.Location-search-header {
    background-color: white;
    padding: 15px 15px;
}
.Location-search-header .Map-icon{
    float: right;
    width: 20px;
}
.Location-search-header .Back-black-icon{
    float: left;
    width: 15px;
}