.account-version-container {
    display: flex;
    flex-direction: column;
    padding-top: 14px;
    .account-necessary-links {
        padding: 20px 20px 60px;
        display: flex;
        flex-direction: column;
        a {
            @extend .link;
            margin-bottom: 8px;
            color: $bleu-de-france;
            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
    .account-version-number {
        display: flex;
        justify-content: center;
        color: $spanish-gray;
        font-weight: 400;
        font-size: 12px;
        @extend .overline;
    }
    .account-logout-container {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        
        button {
            color: $bittersweet;
            border: 1px solid $bittersweet;
            @extend .button;
            box-shadow: none;
            background: none;
            width: 100%;
            border-radius: 5px;
            padding: 8px;
            &:focus {
                outline: none;
            }
        }
    }
}
.logout-btn {
    display: flex;
    padding: 0 20px;
    cursor: pointer;
    img {
        width: 20px;
        height: 20px;
        margin-right: 16px;
    }
    .account-links-box-title {
        @extend .subtitle1;
        color: $charcoal;
    }
}
.logout-bottom-sheet-container {
    z-index: 9;
    position: relative;
    width: 100%;
    // height: 100%;
}