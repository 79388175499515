.News_Box{
  background-color: #FAFBFC;
  height: 80px;
  @include clientV5Shadow();
  padding: 15px;
  flex-basis: 100%;
  display: flex;
  margin: 15px 24px;
  justify-content: space-between;
  .News_Box_Envelope{
    flex-basis: 15%;
    .News_Box_Envelope_Container{
      position: relative;
      width: 28px;
      height: 22px;
      border-radius: 50%;
      margin: 0;
      .News_Day{
        position: absolute;
        font-size: 11px;
        color: #0070E0;
        top: 17px;
        width: 27px;
        right: 0;
        font-weight: bold;
      }
      .News_Month{
        width: 18px;
        position: absolute;
        font-size: 7px;
        color: #2F353B;
        top: 10px;
        z-index: 1000;
        font-weight: bold;
        right: 5px;
        z-index: 0;
      }
    }
    .News_Box_Envelope_Container img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 12px;
    }

  }
  .News_Box_Right{
    margin: 6px 0;
    flex-basis: 85%;
    overflow: hidden;
  }
}

.News_Box .News_Box_Title{
  font-size: 13px;
  font-weight: bold;
  color: black;
}

.News_Box .News_Box_Summary{
  font-size: 12px;
  font-weight: bold;
  color: gray;
  white-space: nowrap;
  margin: 4px 0 0 0;
  p{
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

.News_Box .News_Box_Img {
  width: 8px;
  padding: 14px 0;
}
