.saved-places-address-detail {
    height: 100%;
    .title-bar-container {
        height: 44px;
        background-color: white;
        z-index: 100;
        .right-text {
            font-weight: 500;
            letter-spacing: 0.1px;
            color: #0C87FA;
            position: absolute;
            right: 19px;
        }
    }
    .saved-places-form {
        border-radius: 24px 24px 0px 0px;
        height: 70%;
        z-index: 2;
        position: relative;
        background: white;
        margin-top: 28px;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
        .saved-places-address {
            padding-top: 22px;
            padding-bottom: 100px;
            input.address {
                color: $spanish-gray;
                -webkit-text-fill-color: $spanish-gray;
                opacity: 1;
            }
        }
        .form-group-container {
            background-color: white;
            &.address {
                border-bottom: 0;
            }
        }
        .form-group-container-textarea textarea {
            background-color: white;
        }
    }
    .full-height {
        height: 100%;
        z-index: unset;
        .saved-places-address {
            padding-bottom: 100px;
        }
    }
}
.saved-place-address {
    border-bottom: none;
    input {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: $spanish-gray;
        -webkit-text-fill-color: $spanish-gray;
        opacity: 1;
    }
}
.saved-place-type-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: $spanish-gray;
    padding: 0;
    margin: 0;
    padding-left: 4px;
}
.saved-places-types {
    display: flex;
    width: 100%;
    :first-child {
        padding-left: 5px !important;
    }
    div {
        padding: 0 8px !important;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: $bleu-de-france;
        opacity: 0.5;
    }
    .selected-type {
        opacity: unset;
    }
}
.google-map-wrapper {
    height: 30%;
    top: 42px;
    position: relative;
    #google-map {
        top: 0 !important;
        height: 100%;
    }
    .edit-location-btn {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        height: 100%;
        top: 0;
        z-index: 2;
        position: absolute;
        bottom: 14px;
        display: flex;
        width: 100%;
        justify-content: center;
        p {
            position: absolute;
            bottom: 25px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.25px;
            color: #0C87FA;
        }
    }
    .location-search-map-icon {
        z-index: 1;
         position: relative !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        //position: fixed;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        svg {
            &.Pin-icon {
                width: 40px;
                height: 40px;
                transition: all 0.8s ease 0s;
            }
            &.Ellipse-icon {
                // position: fixed;
                left: 49%;
                top: 49%;
                z-index: 10;
                width: 8px;
                fill: #4c4c4c70;
            }
        }
        &.dragged {
            svg {
                &.Pin-icon {
                    transition: all 0.8s ease 0s;
                    top: 42%;
                }
            }
        }
    }

}
