.history-mk-transactions-container {
    .title-bar-container { 
        justify-content: flex-start;
        p {
            text-align: right;
            margin: 0;
            padding-left: 24px;
            @extend .subtitle1;
        }
    }

    .history-mk-transactions-title {
        @extend .body1;
        color: $spanish-gray;
        margin: 0 0 16px;
    }

    .history-mk-transactions {
        padding: 70px 20px 80px;
        .empty-state {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            width: 100%;
            .empty-state-image {
                width: 50%;
                margin: 24px auto;
            }
            p {
                color: $spanish-gray;
                @extend .body2;
            }
        }
    }

    .call-to-action {
        position: fixed;
        bottom: 0;
        max-width: 412px;
        width: 100%;
        padding: 20px;
        background-color: $doctor;
        z-index: 100;
        .button-container.MuiButtonBase-root.MuiButton-root {
            padding: 8px;
        }
    }
}
.mk-transactions-skeleton {
    padding: 70px 20px 0;
}