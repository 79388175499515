.datepicker-modal {
  margin: 0 auto;
  left: 0;
  max-width: 414px;
}
.datepicker.ios {
  border-radius: 20px 20px 0 0;
  .datepicker-header {
    padding: 0 !important;
  }
  .queued-jobs-container-title {
    text-align: center;
    margin: 0;
    padding: 20px 20px 8px;
    margin-bottom: 0;
    color: $charcoal;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
  }
  .datepicker-content {
    padding: 0 20px;
    .datepicker-col-1 {
      &:first-child {
        .datepicker-wheel {
          border-radius: 5px 0 0 5px;
        }
      }
      &:last-child {
        .datepicker-wheel {
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }
  .datepicker-wheel {
    border: 0;
    background-color: $grainsboro;
  }
  .datepicker-navbar {
    position: relative !important;
    padding: 20px !important;
    display: flex;
    flex-direction: row-reverse;
    .datepicker-navbar-btn {
      text-decoration: none;
      &:first-child  {
        flex: 1 1 70%;
        @extend .button;
        background: $bleu-de-france;
        border-radius: 5px;
        color: $doctor;
        text-transform: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:last-child {
        flex: 1 1 30%;
        @extend .button;
        border-radius: 5px;
        margin-right: 8px;
        color: $bittersweet;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}