.Job_Chat_History_Menu_Box {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  margin: 215px 16px 0;
  .J_C_H_M_B_Row {
    padding: 16px;
    display: flex;
    width: 100%;
    border-top: 1px solid $grainsboro;
    &:first-child {
      border-top: 0;
    }
  }
  .J_C_H_M_B_Row_Icon {
    .J_C_H_B_Row_Icon_Img {
      width: 24px;
      height: 24px;
      &.mykoin-icon {
        padding: 4px;
      }
    }
  }
  .J_C_H_M_B_Row_Text {
    h5 {
      padding-left: 16px;
      color: $charcoal;
      margin: 0;
      @extend .subtitle1;
    }
  }
}

.Cancel_Btn_Box {
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  .Cancel_Job_Button {
    text-transform: none !important;
    background-color: $doctor;
    color: $bittersweet;
    border: 1px solid $bittersweet;
    margin: 32px 0;
    outline: none;
    box-shadow: none;
    width: 100%;
    border-radius: 5px !important;
    padding: 8px;
    @extend .button;
    &:disabled {
      background-color: #C5C5C5 !important;
    }
  }
}
.chat-menu-title-bar {
  .title-bar-container {
    &.has-not-fade {
      .title-bar-icon-inner {
        .heading {
          padding-left: 8px;
        }
        .title-card-body {
          .long-heading {
            width: auto;
          }
        }
      }
    }
  }
  .title-bar-container {
    .title-bar-icon {
      flex: 1 1 96%;
      max-width: 96%;
      .title-bar-icon-inner {
        display: flex;
      }
      .heading {
        padding: 0 8px 0 32px;
        overflow-wrap: break-word;
        text-align: left;
        @extend .subtitle1;
      }
      .lozenge-container{
        align-items: center;
        width: 100%;
        max-width: fit-content;  
        .lozenge-inner {
          height: fit-content;
          .lozenge-title {
            span {
                @extend .overline;
                text-transform: uppercase;
            }
          }
        }
      }
    }
  }
  &.chat-menu-title-bar-container {
    .title-bar-container {
      &.has-not-fade {
        .title-bar-icon-inner {
          width: 100%;
          .heading {
            padding-left: 8px;
          }
        }
      }
      .title-bar-icon {
        flex: 1 1 100%;
        max-width: 100%;
        display: flex;
        .lozenge-container {
          // max-width: 20%;
        }
        .fader {
          &.fader-left {
            background: linear-gradient(
              to left,
              rgba(250, 251, 252, 0),
              rgba(250, 251, 252, 1)
              );
          }
          &.fader-right {
            background: linear-gradient(
              to right,
              rgba(250, 251, 252, 0),
              rgba(250, 251, 252, 1)
              );
          }
        }
      }
    }
  }
}
.add-transaction-bottom-sheet {
  padding: 20px;
  .bottom-sheet-info {
    .heading {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      h2 {
        margin-bottom: 0;
      }
      .sad-icon {
        width: 45px;
        height: 45px;
      }
    }
    p {
      text-align: left;
    }
  }
  .button-container.MuiButtonBase-root.MuiButton-root {
    padding: 8px;
  }
}
.live-location-loading {
  padding-top: 65px;
  height: 230px;
  position: absolute;
  width: 100%;
  .spinner-box {
    padding-top: 65px;
    height: 230px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }
}