.Internet-connection .Modal {
    display: block;
    background-color: rgba(110, 110, 110, 0.55);
}
.Internet-connection .Modal .modal-dialog {
    margin: 200px 45px
}
.Internet-connection .Modal .modal-content {
    padding: 20px;
    border-radius: 15px;
}
.Internet-connection .Modal .modal-kuya-container {
    position: relative;
    text-align: center;
    margin: auto;
    margin-top: -70px;
}
.Internet-connection .Modal .modal-kuya-container .Card-error{
    width: 100px;
    max-height: 110px;
    margin: auto;
    margin-top: 0px;
}
.Internet-connection .Internet-connection-title p{
    font-size: 18px;
    margin: 0;
    /* font-family: 'lato_black'; */
}
.Internet-connection .message{
    padding: 0;
}
.Internet-connection .message p{
    margin: 0;
    color: #646464;
}
.Internet-connection .Internet-connection-button-container{
    padding: 0px 10px 10px 10px;
}
.Internet-connection .try-button{
    text-transform: none !important;
    background-color: #0488FA !important;
    color: white !important;
    outline: none;
    box-shadow: none;
    border: none;
    width: 100%;
    border-radius: 3px !important;
    padding: 6px !important;
    font-size: 14px !important;
    /* font-family: 'lato_black'; */
}