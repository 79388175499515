.Job-chat-history .Job-chat-history-recorder-IoIosCheckmark.MuiButton-root.Mui-disabled {
  opacity: 0.5;
}
.Job_Chat_History_Record_Voice{
  display: flex;
  flex-wrap: wrap;
  background-color: #181A1E;
  height: 65px;
}
.Job-chat-history .Job-chat-history-recorder .MuiButton-label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Job-chat-history .Job-chat-history-recorder:disabled {
  background-color: #dddddd;
}
.Recording_Timer_Box{
  flex: 1 1 40%;
  width: 40%;
}
.Job_Chat_History_User_Actions_Recorder {
  flex: 1 1 35%;
  width: 35%;
  display: flex;
  .J_C_H_U_A_R_Icon{
    flex: 1 1 30%;
    width: 30%;
    .Gray_Recorder{
      width: 24px;
      height: 24px;
      margin: 16px;
    }
  }
  .J_C_H_U_A_R_Text{
    flex: 1 1 70%;
    width: 70%;
    p{
      font-size: 13px;
      margin: 19px 14px 0 0;
      line-height: 24px;
      color: #B1B3B7;
      text-align: right;
    }
  }

}
.Job_Chat_History_Send_Delete{
  flex: 1 1 25%;
  width: 25%;
  display: flex;
  .J_C_H_S_D_Box{
    flex: 1 1 40%;
    width: 40%;
  }
}
.Job_Chat_History_Record_Voice_Box {
  background-color: #2F353B;
  border-radius: 8px;
  padding: 4px;
  margin: 14px 0;
  height: 32px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  .Timer_Circle{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #C9311A;
    float: right;
    margin: 8px 0;
  }
  .Timer{
    float: right;
    margin: 0 16px 0 8px;
    text-align: right;
  }
}
.Job_Chat_History_Send_Recorder{
  border: none;
  box-shadow: none;
  background-color: transparent;
  border-radius: 50%;
  font-size: 20px;
  outline: none;
  padding: 0;
  min-width: unset;
  margin: 18px 0;
  float: left;
}
.Job_Chat_History_Trash{
  background-color: transparent;
  text-transform: none !important;
  border: none;
  color: white;
  box-shadow: none;
  outline: none;
  padding: 0;
  min-width: unset;
  margin: 18px 0;
  .Orange_Trash{
    width: 24px;
    height: 24px;
  }

}