.Jobs-box-heading-status {
  padding: 0 3px;
  background-color: gray;
  text-align: center;
  border-radius: 4px;
  color: $doctor;
  font-weight: normal;
  outline: none;
  border: none;
  font-size: 10px;
  line-height: 15px;
  width: fit-content;
  height: fit-content;
  &.orange {
    background-color: $tangerine;
  }
  &.green {
    background-color: $emerald;
  }
  &.yellow {
    background-color: $mango;
  }
}

.Jobs-box-heading-status.Active {
  background-color: #15BD5D;
}