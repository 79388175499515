.form-group-container {
  background-color: $doctor;
  border-bottom: 1px solid $grainsboro;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  position: relative;
  padding: 5px 5px 0;
  &.form-group-contain-icon {
    display: flex;
    padding: 5px 8px;
    .icon {
      margin-right: 5px;
    }
  }
  label {
    @extend .assistive;
    display: flex;
    color: $spanish-gray;
    margin: 0;
  }

  input,
  .form-control {
    @extend .body2;
    border: 0;
    box-shadow: none;
    padding: 0;
    color: $eerie-black;
    border-radius: 8px;
    background-color: transparent;
    height: 28px;
    &:focus {
      outline: none;
    }
  }

  &.focused {
    border: 1px solid $aero;
    border-radius: 8px;
    @extend .subtle-black-shadow;
    transition: border .2s linear;
    label {
      color: $eerie-black;
      transition: color .1s linear;
    }
  }
  &.input-error {
    border: 1px solid $cinnabar;
    border-radius: 8px;
    transition: border-color .2s linear;
    label {
      color: $cinnabar;
      transition: color .1s linear;
    }
    + .error {
      @extend .overline;
      color: $cinnabar;
      padding-left: 7px;
    }
  }
  .Form-select {
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    padding-left: 5px;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
  }
  .form-select-box {
    @extend .body2;
    display: inline-block;
    width: 100%;
    box-shadow: none;
    outline: none;
    border: 0;
    background-color: transparent;
    padding: 0;
    height: 20px;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
  }
  .form-select-box.selected-city {
    color: #000
  }
}