.booking-location-box {
  padding: 24px 20px 100px;

  h2 {
    @extend .subtitle1;
    margin: 0 0 16px;
    color: $charcoal;
  }

  .location-box-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 15px 8px;
    border-bottom: 1px solid $grainsboro;
    width: 100%;
    background-color: $doctor;
    .icons-wrapper {
        display: flex;
        align-items: center;
    }
  }
}