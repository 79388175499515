.cancel-job-bottom-sheet {
  background-color: $doctor;
  padding: 0 12px;
  border-radius: 20px 20px 0 0;
  @media (min-width: 400px) {
    padding: 0 24px;

  }
}
.Job-chat-history-cancel {
   width: 100%;
  .title {
    @extend .subtitle1;
    margin: 0;
    color: $charcoal;
    text-align: center;
    padding-top: 24px;
  }

  .text-title {
    color: #949494;
  }

  .lets-get-started-button {
    text-transform: none !important;
    background-color: $bleu-de-france !important;
    color: white;
    outline: none;
    box-shadow: none;
    border: none;
    width: 100%;
    border-radius: 3px;
    font-weight: bold;
    padding: 6px;
    font-size: 12px !important;
  }

  ul {
    list-style: none;
    margin: 16px 0 0;
    padding: 0;
    overflow: auto;

    li {
      display: block;
      position: relative;
      width: 100%;
      margin-bottom: 16px;
      @media (min-width: 400px) {
        margin-bottom: 24px;
      }
      input[type=radio] {
        position: absolute;
        visibility: hidden;
      }

      .check {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 5;
        transition: border .25s linear;
        -webkit-transition: border .25s linear;

        label {
          display: block;
          position: relative;
          z-index: 9;
          cursor: pointer;
          transition: all 0.25s linear;
          margin-bottom: 0;
          color: $charcoal;
          font-weight: 600;
          // padding: 8px;
          padding: 16px;
          @media (min-width: 400px) {
            padding: 16px;
          }
        }

        &::before {
          display: block;
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          border-radius: 8px;
          margin: auto;
          transition: background 0.25s linear;
          -webkit-transition: background 0.25s linear;
          background-color: $doctor;
          border-radius: 8px;
          @extend .big-black-shadow;
        }
      }
    }
  }

  input[type=radio]:checked ~ .check {
    &::before {
      background: $sapphire;
      box-shadow: none;
    }
    label {
      color: $doctor;
    }
  }

  .Job-chat-history-cancel-textarea {
    width: 100%;
    border-bottom: 1px solid $grainsboro;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    outline: none;
    box-shadow: none;
    background-color: $doctor;
    &::placeholder {
        color: $silver-chalice;
        font-size: 12px;
    }

    + p {
      color: $bittersweet;
      position: absolute;
      right: 0px;
      font-size: 20px;
      top: -5px;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .retry-button {
    text-transform: none !important;
    background-color: $doctor !important;
    color: $bittersweet;
    outline: none;
    box-shadow: none;
    border: 1px solid $bittersweet;
    width: 100%;
    border-radius: 5px;
    padding: 8px;
    @extend .button;
    flex-basis: 70%;
    max-width: 70%;
    margin-right: 16px;
  }
  .cancel-button {
    text-transform: none !important;
    color: $doctor;
    background-color: $bleu-de-france;
    outline: none;
    box-shadow: none;
    border: 1px solid $bleu-de-france;
    width: 100%;
    border-radius: 5px;
    padding: 8px;
    @extend .button;
    flex-basis: 30%;
    max-width: 30%;
    width: 100%;
    &:hover, &:focus, &:active {
      color: $doctor;
      background-color: $bleu-de-france;
    }
  }
  .Job-chat-modal-button-container {
      display: flex;
      width: 100%;
      padding-top: 24px;
      justify-content: space-between;
  }
  .chat-with-support {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 16px;
     span {
       padding-left: 8px;
       color: $bleu-de-france;
        @extend .button;
     }
  }
}