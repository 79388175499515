.rce-container-mbox  {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 10px;
    &.system {
    .rce-mbox {
      padding: 0 20px 3px;
      .rce-mbox-body {
        background-color: $white-smoke;
        border-radius: 8px;
        padding: 16px;
        display: flex;
        .rce-mbox-text  {
          flex: 1 1 90%;
        }
        .icon {
          margin-right: 8px;
          width: 21px;
          height: 24px;
          flex: 1 1 25px;
          svg {
            width: 100%;
            display: block;
          }
        }
      }     
    }
    .timezone {
      display: flex;
      justify-content: flex-end;
      color: $spanish-gray;
      @extend .assistive;
    }
  }
}