.how-it-works-carousel-wrapper {
    margin: 0 20px 24px 20px;
    .how-it-works-title {
        font-weight: 600;
        font-size: 19px;
        line-height: 28px;
        letter-spacing: 0.15px;
        color: $charcoal;
        margin-bottom: 4px;
        margin-left: 8px;
    }
    .slider-wrapper {
        border-radius: 8px;
    }
    .slick-slider {
        .slick-list {
            border-radius: 8px;
            height: 164px;
        }
        ul {
            position: absolute;
            width: 100px;
            bottom: 2px;
            right: 2px;
            li {
                margin: 0 4px;
                a {
                    .MuiLinearProgress-colorPrimary {
                        height: 2px;
                        width: 20px;
                        background-color: white;
                        border-radius: 2px;
                    }
                    .MuiLinearProgress-barColorPrimary {
                        background-color: white;
                    }
                }
                &.slick-active {
                    a {
                        .MuiLinearProgress-barColorPrimary {
                            background-color: $dark-orange;
                        }
                    }
                }
            }
        }
        .slick-prev {
            display: block;
            position: absolute;
            left: 2px;
            z-index: 2;
            height: 100%;
            width: 60px;
            &:before {
                visibility: hidden;
            }
        }
        .slick-next {
            display: block;
            position: absolute;
            right: 2px;
            z-index: 2;
            height: 100%;
            width: 60px;
            &:before {
                visibility: hidden;
            }
        }
    }
}
.slider-item {
    border-radius: 8px;
    height: 150px;
    position: relative;
    p {
        position: absolute;
        max-height: 72px;
        width: 193px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $charcoal;
        right: 8px;
        top: 16px;
    }
    img {
        height: 134px;
        width: 134px;
        position: absolute;
        bottom: 0px;
        left: 0px;
    }
}
.alice-blue {
    background-color: $alice-blue;
}
.aero-blue {
    background-color: $aero-blue;
}
.pale-pink {
    background-color: $pale-pink;
}

@media only screen and (max-width: 380px) {
    .slider-item {
        p {
            right: -8px;
        }
    }
}