.booking-overlaid-button-container {
    position: fixed;
    background-color: #FFFFFF;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    padding: 0 20px 20px;
    bottom: 0;
    width: 100%;
    z-index: 100;
    .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
        width: 100%;
        padding: 8px;
        margin-top: 8px;
    }
    .booking-button-icon {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 16px;
        .icon {
            position: absolute;
            top: -6px;
            right: 3px;
        }
    }
    .cancel-booking {
        .MuiButtonBase-root.MuiButton-root.MuiButton-text {
            color: $bittersweet;
            margin-top: 8px;
            background-color: $doctor;
            box-shadow: none;
            &:focus {
                box-shadow: none;
            }
        }
    }
    .schedule-booking-button {
        margin: 8px 0 0;
        p {
            margin: 0;
        }
        svg {
            width: 25px;
            height: 25px;
            margin-right: 5px;
        }
    }
}
.booking-button-fadein {
    position: relative !important;
    display: none !important;
}
@media (min-width: 770px) {
    .booking-overlaid-button-container  {
        max-width: 414px;
    }
}