.location-search-map {
    #google-map {
        top: 54px;
        bottom: 85px;
    }
    .title-bar-container {
        padding: 16px;
        border-bottom: 0.5px solid $silver-chalice;
    }
    .location-search-input {
        position: absolute;
        width: 100%;
        height: 74px;
        background: transparent;
        margin-top: 61px;
        .form-group-container {
            border-color: #647A90;
            margin: 0 16px;
            input {
                height: 32px;
                &::placeholder {
                    color: $silver-chalice;
                }
            }
        }
    }
    .background {
        background: $doctor;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        height: 67px;
        margin-top: 46px;
        padding-bottom: 6px;
        .location-search-box {
            width: 100%;
        }
        .form-group-container {
            display: flex;
            justify-content: space-between;
            border-color: #647A90;
            margin: 0 16px;
            input {
                height: 32px;
                width: 80%;
                &::placeholder {
                    color: $silver-chalice;
                }
            }
        }
        p {
            margin: 0;
            margin-right: 16px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.25px;
            color: $bleu-de-france;
        }
    }
    .form-group-container {
        .icon-input-container {
            display: flex;
            width: 100%;
        }
    }
    .location-search-suggestions {
        position: absolute;
        z-index: 11;
        margin-top: 108px;
        height: 100%;
        box-shadow: 0px 2px 4px rgba(24, 26, 30, 0.15);
        .location-search-suggestions-item {
            border-bottom: 0;
            justify-content: flex-start;
            padding-left: 0;
            .location-search-suggestions-title {
                padding-left: 0;
                margin-right: 0;
            }
        }
    }
    &.set-first-location  {
        height: 100%;
        .location-search-map-icon {
            position: fixed;
            top: 46%;
            transform: translate(-50%, -46%);
        }
    }
    .location-search-map-icon {
        z-index: 1;
        // position: absolute;
        // left: 46%;
        // top: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        left: 50%;
        top: 51%;
        transform: translate(-50%, -50%);
        svg {
            &.Pin-icon {
                width: 40px;
                height: 40px;
                transition: all 0.8s ease 0s;
            }
            &.Ellipse-icon {
                // position: fixed;
                left: 49%;
                top: 49%;
                z-index: 10;
                width: 8px;
                fill: #4c4c4c70;
            }
        }
        &.dragged {
            svg {
                &.Pin-icon {
                    transition: all 0.8s ease 0s;
                    top: 42%;
                }
            }
        }
    }
    .location-search-map-current {
        position: absolute;
        right: 0;
        bottom: 100px;
        z-index: 10;
        cursor: pointer;
        padding: 0 15px;
        .Current-icon{
            width: 35px;
            margin: auto;
        }
    }
    .location-search-map-confirm {
        background-color: white;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 24px 24px 0px 0px;
        padding: 20px 24px;
        button {
            width: 100%;
        }
    }
}
