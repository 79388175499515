.location-search-set {
    .Modal {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(110, 110, 110, 0.55);
        .modal-dialog {
            margin: 0 20px;
        }
        .modal-content {
            padding: 15px;
            border-radius: 10px;
            border: 0;
            box-shadow: none;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .modal-kuya-container {
            position: relative;
            text-align: center;
            margin: auto;
            margin-top: -75px;
            svg {
                width: 100px;
                max-height: 100px;
                margin: auto;
                margin-top: 0px;
            }
        }
        .location-search-set-title {
            margin-bottom: 8px;
            p {
                margin-bottom: 0;
                @extend .h6;
                color: $charcoal;
            }
        }
        .message {
            margin-bottom: 8px;
            p {
                @extend .caption;
                margin-bottom: 0;
                color: $eerie-black;
            }
        }
        .action {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .button-action {
                flex-basis: 50%;
                max-width: 50%;
                padding-right: 16px;
                &:last-child {
                    padding-right: 0;
                }
                button {
                    font-size: 14px;
                    width: 100%;
                }
            }
        }
    }
}