.welcome {
    background-color: $doctor;
    padding: 0 20px;
    .welcome-img {
        margin: 0px -8px 10px;
        width: 120px;
    }
    .welcome-title {
        @extend .h6;
        color: $charcoal;
        margin: 0;
    }
    .welcome-subtitle {
        @extend .caption;
        color: $charcoal;
    }
    .welcome-agree {
        @extend .caption;
        color: $silver-chalice;
        margin: 0;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
    }
    .welcome-agree-link {
        @extend .caption;
        color: $silver-chalice;
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 0;
        
    }
    .welcome-agree-policy,
    .welcome-agree-terms {
        @extend .caption;
        color: $bleu-de-france;
        text-decoration: underline;
        margin: 0 5px;
        font-weight: 800;
        font-family: Poppins;
        font-size: 12px;
        line-height: 18px;
    }
    .welcome-agree-container {
        
    }
    
    .description {
        @extend .body2 ;
        margin: 20px 0;
        color: $gunmetal;
    }
    .enter-phone-number {
        display: flex;
        width: 100%;
        padding: 25px 0;
        .country-code {
            flex-basis: 25%;
            max-width: 25%;
            padding-right: 8px;
            .form-group-container {
                padding: 5px;
                @media (min-width: 400px) {
                    padding: 5px 8px;
                }
            }
            label {
                color: $silver-chalice;
            }
            .welcome-select-country {
                background-color: $doctor;
                width: 100%;
                border-radius: 8px;
                outline: none;
                -webkit-appearance: none;
                text-align: center;
                border: none;
                padding: 4px 0;
                @media (min-width: 400px) {
                    padding: 4px 8px 4px 0;
                }
            }
            .welcome-select-country:-moz-focusring {
                color: transparent;
                text-shadow: 0 0 0 $doctor;
            }
            .welcome-select-country-firefox {
                padding-left: 0 !important;
            }
        }
        .phone-number {
            flex-basis: 75%;
            max-width: 75%;
            padding-left: 8px;
            .phone-number-container {
                padding: 5px 8px;
                input {
                    width: 100%;
                    padding: 0;
                    color: $eerie-black;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                    &::placeholder {
                        color: $silver-chalice;
                    }
                }
            }
        }
    }
    .welcome-being-mykuya {
        position: fixed;
        bottom: 24px;
        left: 0;
        right: 0;
        padding: 0 20px;
        .text-info {
            color: $charcoal;
            margin: 16px 0;
            @media (min-width: 400px) {
                margin: 24px 0;
            }
        }
        a {
            color: $bleu-de-france;
            font-weight: bold;
            @extend .body2 ;
            display: block;
        }
    }
    .continue-auth {
        margin-bottom: 25px;
        .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
            width: 100%;
            padding: 8px;
        }
    }
}