@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../node_modules/slick-carousel/slick/slick.css";
@import "../node_modules/slick-carousel/slick/slick-theme.css";


@font-face {
  font-family: 'digital';
  src: url('./assests/fonts/ds_digit.ttf') format('truetype');
}
/* @font-face {
  font-family: 'lato_black_italic';
  src: url('./assests/fonts/lato/lato_black_italic.ttf') format('truetype');
}
@font-face {
  font-family: 'lato_black';
  src: url('./assests/fonts/lato/lato_black.ttf') format('truetype');
} */
/* @font-face {
  font-family: 'lato_bold';
  src: url('./assests/fonts/lato/lato_bold.ttf') format('truetype');
}
@font-face {
  font-family: 'lato_regular';
  src: url('./assests/fonts/lato/lato_regular.ttf') format('truetype');
} */
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

body {
  overflow: hidden;
  margin: 0;
  font-family: 'Poppins', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none; /* Prevent to pull refresh */
  background-color:#FAFBFC !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#launcher {
  display: none;
}

.Modal.modal {
  display: block;
  background-color: rgba(110, 110, 110, 0.55);
}
@media (min-width: 770px) {
  .fixed-wrapper {
    border: 1px solid #dededf;
  }
  .fixed-wrapper, .wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 414px;
  }
  .fixed-wrapper__main {
    position: relative;
    min-height: 100vh;
  }
  .Bottom_Sheet_Parent_Home_Page > div {
    max-width: 100% !important;
  }
  .Bottom_Sheet_Parent_Home_Page > div {
    max-width: 412px !important;
  }
  iframe#webWidget {
    height: 100% !important;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 446px;
    width: 446px !important;
    top: -16px;
    max-height: 100vh !important;
  }
}
.fixed-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.fixed-wrapper::-webkit-scrollbar {
  width: 0;
}
@media (max-width: 770px) {
  .Bottom_Sheet_Parent_Home_Page > div {
    max-width: 100% !important;
  }
  .reactour__helper.onboard-message-box {
    max-width: -webkit-fill-available !important;
    margin: 0 10px 0 0;
  }
}
.mask {
  opacity: 0.7
}
.reactour__helper.onboard-message-box {
  padding: 12px;
  max-width: 380px;
  width: 100%;
  font-size: 13px;
  background-color: #23282C;
  color: #fff;
}
.reactour__helper.onboard-message-box > div {
  margin-top: 12px;
  padding-top: 6px;;
  border-top: 1px solid #3B424A;
  justify-content: space-between;
}
.reactour__helper.onboard-message-box > div .next-button {
  color: #fff;
  background-color: #3B424A;
  font-size: 13px;
  padding: 6px 12px;
  border-radius: 5px;
  margin-bottom: 0;
}
.reactour__helper.onboard-message-box > div .skip-button {
  color: #fff;
  font-size: 10px;
  padding: 6px 12px;
  border-radius: 5px;
  margin-bottom: 0;
}