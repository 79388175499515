@import "./SavedPlacesOthers/SavedPlacesOthers";
@import "./SavedPlacesFavorites/SavedPlacesFavorites";
@import "./SavedPlacesAddress/SavedPlacesAddress";
@import "./SavedPlacesUpdateAddress/SavedPlacesUpdateAddress";
@import "./AccordionMenu/AccordionMenu";

//Shared style between all pages related to saved places
.saved-places {
    height: 100%;
    .saved-places-spacer {
        padding-top: 10px;
    }
    .saved-places-container {
        padding: 65px 20px 20px;
        width: 100%;
        padding-bottom: 100px;
    }
    .saved-places-no-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .no-address-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            h6 {
                font-weight: 400;
                font-size: 14px;
                line-height: 23px;
                text-align: center;
                color: $charcoal;
                margin-top: 0;
                margin-bottom: 16px;
            }
            p {
                margin: 0;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                color: $silver-chalice;
            }
        }
    }
    .skeleton-saved-places  {
        padding-top: 0;
    }
    .icon {
        margin-right: 15px;
        display: flex;
    }
    .title-container {
        .name {
            @extend .h6;
        }
        p {
            @extend .subtitle2;
            margin:0;
            color: $charcoal;
        }
        .sub-title {
            @extend .caption;
            color: $silver-chalice;
            display: flex;
            font-weight: normal;
            height: 20px;
            width: 100%;
            overflow-y: hidden;
            text-overflow: ellipsis;
            flex-direction: column;
        }
        .blue-sub-title {
            @extend .caption;
            display: flex;
            align-items: center;
            color: $bleu-de-france;
        }
        .forward-icon {
            margin-left: 5px;
            width: 6px;
        }
    }
    .add-new-address {
        width: 100%;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 20px;
        background-color: $doctor;
        &.button-container.MuiButtonBase-root.MuiButton-root {
            width: 100%;
            border: 1px solid $bleu-de-france;

        }
    }
    .add-shadow {
        background: white;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    }
}

@media (min-width: 770px) {
    .saved-places {
        .add-new-address {
            max-width: 412px;
            margin: 0 auto;
        }
    }
}