.Job_Rating_Partner_Section{
  display: flex;
  flex: 1;
  flex-basis: 100%;
  flex-direction: column;
  padding: 32px 0 0 0;
  border-top: 1px solid $grainsboro;
  .Partner_Section_Top{
    display: flex;
    align-items: center;
    .Partner_Section_Img{
      img{
        width: 48px;
        height: 48px;
        border-radius: 100%;
      }
    }
    .Partner_Section_Name{
      display: flex;
      flex-direction: column;
      padding-left: 16px;
      h3 {
        font-weight: 600;
        line-height: 28px;
        margin: 0;
        font-size: 19px;
        text-align: left;
        color: $charcoal;
      }
      span {
        color: $spanish-gray;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
      }
    }
  }

  .Partner_Section_Text{
    margin: 24px 0 0;
    h5{
      text-align: left;
      font-size: 15px;
      line-height: 22px;
      color: #3B424A;
      margin: 0;
    }
  }
}