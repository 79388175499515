.title-bar-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: $doctor;
    p {
        margin: 0 auto;
        display: block;
        text-align: center;
        @extend .subtitle1;
        color: $charcoal;
    }
}
.has-long-heading {
    .title-bar-icon-inner {
        display: flex;
        align-items: center;
        width: 100%;
        .title-card-body {
            display: flex;
            width: 100%;
        }
    }
    .long-heading,
    .long-subtitle,
    .long-heading-info,
    .long-subtitle-info {
        max-width: 70%;
        .heading,
        .subtitle {
            padding-left: 4px;
        }
    }
    .lozenge-container {
        max-width: 20%;
    }
    .back-button {
        max-width: 10%;
    }
}
.long-heading,
.long-subtitle,
.long-heading-info,
.long-subtitle-info {
    width: 70%;
    overflow: hidden;
}
.text-container {
    padding: 5px 0;
    min-width: 0;
    white-space: nowrap;
    position: relative;
    &.partner-name-container {
        width: 100%;
    }
    span {
        display: inline-block;
        @extend .subtitle1;
    }
    .animate {
        position: relative;
        animation: leftright 4s infinite alternate ease-in-out;
    }
}
.fader {
    position: absolute;
    top: 0;
    height: 100%;
    width: 25px;

    &.fader-left {
        left: 0;
        background: linear-gradient(
        to left,
        rgba(245, 245, 245, 0),
        rgba(245, 245, 245, 1)
        );
    }

    &.fader-right {
        right: 0;
        background: linear-gradient(
        to right,
        rgba(245, 245, 245, 0),
        rgba(245, 245, 245, 1)
        );
    }
}

@media (min-width: 770px) {
    .title-bar-container {
        max-width: 412px;
    }
}

@keyframes leftright {
  0%,
  20% {
    transform: translateX(0%);
    left: 0%;
  }
  80%,
  100% {
    transform: translateX(-100%);
    left: 100%;
  }
}