.News_Detail{
  display: flex;
  flex-direction: column;
  margin: 64px 0 0 0;
  background-color: $doctor;
  .Title_Date_Box{
    flex-basis: 100%;
    margin: 0 24px;
  }
  .News_Detail_Thumbnail{
    margin: auto;
  }
}

.News_Detail .News_Detail_Body{
  white-space: pre-line;
}
.detail-news-loading {
  padding: 60px 20px 0;
}