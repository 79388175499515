.location-search-destination-box {
    padding: 16px 0 0;
    display: flex;
    align-items: center;
    .location-search-box-inner {
        display: flex;
        align-items: center;
        padding-left: 12px;
        width: 100%;
        justify-content: space-between;
        input {
            border: 0;
            width: 100%;
            background: transparent;
            box-shadow: none;
            border-radius: 0;
            text-align: right;
            padding: 0;
            @extend .link;
            caret-color: $doctor;
            cursor: pointer;
            &::placeholder {
                color: $bleu-de-france;
            }
            &::-moz-placeholder,
            &::-ms-placeholder,
            &::-webkit-placeholder,
            &::-o-placeholder {
                color: $bleu-de-france;
            }
            &:focus {
                outline: none;
            }
        }
    }
    &.has-min-point {
        padding: 15px 8px;
        border-bottom: 1px solid $grainsboro;
        .location-search-box-inner {
            input {
                text-align: left;
                &::placeholder {
                    color: $spanish-gray;
                    font-weight: 400;
                }
                &::-moz-placeholder,
                &::-ms-placeholder,
                &::-webkit-placeholder,
                &::-o-placeholder {
                    color: $spanish-gray;
                    font-weight: 400;
                }
                &:focus {
                    outline: none;
                }
            }
        }
    }
}
.add-stop-button {
    float: right;
    width: 75px;
    .location-search-destination-box {
        padding: 8px 0;
        .location-search-box-inner {
            justify-content: flex-end;
            padding-left: 0;
            input {
                width: auto;
            }
        }
    }
}