.Service-box .Service_Box_Container {
  border-radius: 50%;
  background: #fff;
  width: 40px;
  height: 40px;
  margin: auto;
  position: relative;
  overflow: hidden;
}
.Service-box img{
  width: 100%;
  height: 100%;
}
.Service-box p{
  font-size: 10px;
  text-align: center;
  white-space: nowrap;
}