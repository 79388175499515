.xendit-min-limit-container {
  min-height: 515px !important;
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media (min-width: 400px) {
    padding: 24px;
  }
  background-color: $doctor;
  .xendit-min-limit-img {
    width: 100%;
    height: 223px;
    display: flex;
    justify-content: center;
    img {
      width: 223px;
    }
  }
  .xendit-min-limit-header {
    display: flex;
    height: 90px;
    flex-direction: column;
    justify-content: center;
    margin: 16px 0 24px;
    text-align: center;
    h2 {
      color: $charcoal;
      @extend .h6;
      margin: 0;
    }
    h6 {
      color: $charcoal;
      font-size: 13px;
      line-height: 20px;
      margin: 5px 0 0;
    }
  }
  //.xendit-min-limit-body {
  //  display: flex;
  //  align-items: center;
  //  padding: 24px 16px;
  //  img {
  //    width: 16px;
  //    height: 16px;
  //  }
  //  p {
  //    color: $charcoal;
  //    margin-bottom: 0;
  //    padding-left: 24px;
  //    @extend .caption;
  //
  //  }
  //}
  .xendit-min-limit-bottom {
    display: flex;
    height: 115px;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    .button-outline.MuiButtonBase-root.MuiButton-root,
    .button-container.MuiButtonBase-root.MuiButton-root {
      width: 100%;
      padding: 8px;
    }
    .button-outline.MuiButtonBase-root.MuiButton-root {
      margin: 16px 0 8px;
    }
    .chat-with-support {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 8px;
      span {
        padding-left: 8px;
        color: $bleu-de-france;
        @extend .button;
      }
    }
  }
}
.xendit-min-limit-bottom-sheet {
  .Sheet_Content {
    width: 100%;
  }
}