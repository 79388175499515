@import "./BillingInformationCard/BillingInformationCard";
@import "./BillingInformationCardItem/BillingInformationCardItem";
@import "./BillingInformationBalance/BillingInformationBalance";
@import "./BillingInformationAdd/BillingInformationAdd";
@import "./BillingInformationSafely/BillingInformationSafely";
@import "./DeletePaymentErrorBottomSheet/DeletePaymentErrorBottomSheet";
@import "./BillingGCash/BillingGCash";
.billing-information {
    height: 85%;
    position: absolute;
    background-color: $doctor;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    padding-top: 70px;
    &::-webkit-scrollbar {
        width: 0;
    }
    .title,
    .title-cards {
        padding: 65px 20px 20px;
        margin: 0;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        color: $spanish-gray;
    }
    .title-cards {
        padding: 24px 20px 0;
    }
}
.billing-skeleton {
    padding: 0 20px;
}