.Job-list-history {
  position: fixed;
  top: 60px;
  overflow-y: scroll;
  bottom: 0;
  right: 0;
  left: 0;
}

.Job-list-history::-webkit-scrollbar {
  width: 0
}

.Job-list-history .Job-locations .address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.page-title{
  margin: 0 0 10px;
  padding: 65px 20px 0;
  font-size: 19px;
  font-weight: 600;
}
.Parent_Jobs{
  position: fixed;
  top: 10px;
  overflow-y: scroll;
  bottom: 0;
  right: 0;
  left: 0;
}
.Jobs-empty{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 1 1 100%;
  .Home_Empty_Box Empty{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex: 1 1 100%;
  }
}
.Not_Found_Title_Job{
  margin: 0 0 16px 0;
  text-align: center;
  flex: 1 1;
  font-weight: bold;
  color: #3B424A;
  font-size: 18px;
}
.Empty_Search_Text{
  text-align: center;
  margin: 0 24px;
  font-size: 12px;
  color:#3B424A;
  width: 100%;
}
.Empty_Job_Btn {
  background-color: $bleu-de-france   !important;
  color:$doctor!important;
  outline: none;
  box-shadow: none;
  border: none;
  width: 100%;
  border-radius: 3px;
  padding: 8px !important;
  @extend .button;
  text-transform: none;
  font-weight: bold !important;
  margin: 16px 24px !important;
  height: 40px;
  font-size: 16px !important;
  .Dynamic_Parent_Icon {
    height: auto !important;
  }
  .Btn_Text {
    text-transform: none !important;
  }
}
.Empty_Down_Description_Jobs{
  text-align: left;
  margin: 0 8px 0 24px;
  font-size: 12px;
  color:$silver-chalice;
  span{
    &:nth-child(2){
      font-weight: bold;
      color:$bleu-de-france;
    }
  }
}
.Search_Empty_Jobs{
  margin: 0 auto 24px auto;
}
.Active_Margin{
  margin: 0 8px 16px 24px !important;
}
.Jobs_Empty_Box{
  display: flex;
  width: 100%;
  flex: 1 1 100%;
  flex-wrap: wrap;
  flex-direction: column;
}
.my-jobs-tab {
  list-style: none;
  padding: 0;
  max-width: 100%;
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  padding: 0 24px;
  .tab-item-history {
    margin: 0 !important;
    padding: 0 12px;
    &:first-child {
      margin-left: 0 !important;
      padding-left: 0;
    }
  }
}