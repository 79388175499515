.register-form-container {
  display: block;
  position: relative;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0;
  }
  .title-bar-container {
    border-radius: 20px;
  }
  .title {
    color: $charcoal;
    padding: 64px 20px 0;
    @extend .h6;
    margin: 0 0 16px;
  }
  .register-form {
    padding: 90px 20px 70px;
    position: relative;
    .form-group {
      margin-bottom: 40px;
    }
  }
  .sticky-button {
    padding: 20px;
    button {
      width: 100%;
    }
  }
  .select-city-placeholder {
    color: $spanish-gray;
  }
}