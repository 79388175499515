.review-product {
    .tip {
        color: $spanish-gray;
        @extend .caption;
        padding: 24px 28px;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        margin: 0;
    }
    .title-bar-container {
        .title-bar-icon  {
            display: flex;
            align-items: center;
            .lozenge-container {
                padding-left: 16px;
                .color-violet {
                    height: fit-content;
                }
            }
            .lozenge-title {
                p {
                    @extend .overline;
                    text-transform: uppercase;
                }
            }
        }
    }
}
.review-booking-item-container {
    padding: 32px 20px 0;
    &.booking-note {
        margin-bottom: 24px;
    }
    .title {
        color: $spanish-gray;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        margin: 0;
        padding: 0 8px;
    }
    .review-booking-item-inner {
        border-bottom: 1px solid $grainsboro;
        padding: 12px 8px 4px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        p {
            color: $charcoal;
            @extend .caption;
            margin: 0;
            flex-basis: 90%;
            max-width: 90%;
            overflow-wrap: break-word;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            margin: 0;
        }
        + .hint {
            color: $spanish-gray;
            @extend .overline;
            padding: 0 8px;
            display: block;
        }
    }
    .promo-code  {
        margin: 0;
        color: $aero;
        padding: 0 8px;
        @extend .overline;
        font-weight: 600;
    }
}
.review-container {
    position: relative;
    // overflow-y: auto;
}
.cancel-booking-container {
    padding: 0 20px;
    background-color: $doctor;
    .Sheet_Content {
        width: 100%;
    }
    h2 {
        @extend .h6;
        color: $charcoal;
        margin-bottom: 8px;
        text-align: center;
    }
    p {
        @extend .body2;
        color: $charcoal;
        margin-bottom: 0;
        text-align: center;
    }
    .cancel-booking-bottom-sheet {
        padding-top: 32px;
        padding-bottom: 8px;
        img {
            max-width: 100%;
        }
    }
    .cancel-booking-bottom-sheet-buttons {
        display: flex;
        align-items: center;
        margin: 16px 0;
        .cancel-booking-button {
            border: 1px solid $bittersweet;
            flex-basis: 70%;
            max-width: 70%;
            margin-right: 16px;
            border-radius: 5px;
            &.button-container.MuiButtonBase-root.MuiButton-root {
                background-color: $doctor;
                width: 100%;
                color: $bittersweet;
                border: 1px solid $bittersweet;
            }
        }
        .back {
            flex-basis: 30%;
            max-width: 30%;
            &.button-container.MuiButtonBase-root.MuiButton-root {
                width: 100%;
                padding: 8px;
            }
        }
    }
    .chat-with-support {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 0 16px;
        margin-bottom: 20px;
        span {
        padding-left: 8px;
        color: $bleu-de-france;
            @extend .button;
        }
    }
}
.toast-rejected-payment {
    border-radius: 100px;
    background-color: $charcoal;
    background: $charcoal;
    min-height: fit-content;
    width: fit-content;
    margin: 16px auto;
    padding: 16px 24px;
    .Toastify__toast-body.toastify-inner {
        margin: 0;
        text-align: center;
        color: $doctor;
        @extend .caption;
    }
    .Toastify__close-button {
        display: none;
    }
}
.tour-payment-info  {
    .review-booking-item-container {
        // padding: 0 20px 30px;
        margin-top: 30px;
        padding-top: 0;
        margin-left: 20px;
        margin-right: 20px;
        padding: 0;
    }
}