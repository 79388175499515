.account-links {
  display: flex;
  flex-direction: column;
  &.padding-top {
    padding-top: 65px;
  }
  .account-links-box {
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    cursor: pointer;
  }
  .icon-and-title {
    display: flex;
    img {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
  }
  .account-links-box-title {
    @extend .subtitle1;
    color: $charcoal;
  }
}
.border-wrapper {
  display: flex;
  justify-content: center;
  margin: 8px 20px;
  .border-line {
    border: 0.5px solid #DBDBDB;
    width: 100%;
  }
}