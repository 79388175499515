.location-search-saving-container {
    padding: 0 20px;
    .location-search-saving-item {
        border-bottom: 1px solid $grainsboro;
        height: 77px;
        display: flex;
        align-items: center;

        .title-container .sub-title {
            align-items: center;
        }
        
        .location-search-saving {
            padding: 15px;
            width: 100%;
            display: flex;
            align-items: center;
        }
        .bookmark-icon {
            margin-right: 15px;
        }
        .saved-places-title {
            @extend .subtitle2;
            color: $charcoal;
            p {
                margin-bottom: 0;
            }
            span  {
                
            }
        }
    }
}