.open-job-info.request-placed {
    .matching-process-container {
        padding: 12px 0 0;
        .matching-process-row {
            &.job-state {
                padding-left: 2px;
                .icon {
                    width: 9px;
                }
                p {
                    padding-left: 20px;
                }
            }
            &.job-date {
                padding-left: 2px;
            }
        }
        .title {
            color: $charcoal;
            margin: 0 0 16px;
            font-weight: 600;
            font-size: 16px;
        }
        .button-container {
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
            padding: 20px;
            button {
                // width: 100%;
            }
        }
    }
    .matching-process {
        padding-top: 100px;
        max-width: 412px;
        -webkit-overflow-scrolling: touch;
        .matching-process-information {
            position: relative;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            padding-bottom: 120px;
        }
        .matching-process-title {
            margin-bottom: 10px;
        }
        .open-job-info-button {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0 20px 20px;
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: $doctor;
            max-width: 412px;
            margin: 0 auto;
            .button-container {
                position: relative;
                padding: 0;
                &.MuiButtonBase-root.MuiButton-root {
                    width: 100%;
                    padding: 8px;
                }
                &.cancel-booking {
                    margin-top: 10px;
                    border: 1px solid $cinnabar;
                    border-radius: 5px;
                    text-transform: inherit;
                    &.MuiButtonBase-root.MuiButton-root {
                        background-color: transparent;
                        color: $cinnabar;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 27px;
                    }
                }
            }
        }
    }
}