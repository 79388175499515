.Job-chat-history .message-list{
  background-color: $doctor;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  transition: background .3s ease-out .1s;
  width: 100%;
  z-index: 100;
  &::-webkit-scrollbar {
    width: 0;
  }
}
.Job-chat-history {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%;
  .ReactSwipeableBottomSheet--open  , .ReactSwipeableBottomSheet--closed{
    overflow: hidden !important;
  }
}
.Job-chat-history .extra {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.job-chat-history-body {
  flex: 1 1 0;
  order: 2;
  z-index: 1;
  position: relative;
}
.margin-top {
  margin-top: 82px;
}
.confirmation-bottom-sheet {
  padding: 20px;
  .bottom-sheet-info {
    p {
      margin-bottom: 0;
      text-align: center;
    }
    .title-send-mk {
      margin: 8px 0 0;
      font-size: 16px;
    }
    h2 {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      margin-top: 0;
    }
    .call-to-action {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .button-container {
        width: 49%;
        &.MuiButtonBase-root.MuiButton-root {
          border: 1px solid $bleu-de-france;
          padding: 8px;
        }
        &.button-outline.MuiButtonBase-root.MuiButton-root {
          color: $bleu-de-france;
          border-color: $bleu-de-france;
        }
        &.reject-button {
          &.MuiButtonBase-root.MuiButton-root {
            background-color: $cinnabar;
            border: 1px solid $cinnabar;
            padding: 8px;
          }
        }
      }
    }
  }
}
.copy-call-bottom-sheet {
  .bottom-sheet-info  {
    p {
      margin-bottom: 0;
      color: $dim-gray;
      font-size: 16px;
      line-height: 24px;
    }
    .cancel-button {
      &.MuiButtonBase-root.MuiButton-root {
        padding: 8px;
        border: 1px solid $bleu-de-france;
      }
    }
  }
  .call-to-actions {
    margin: 15px 0 10px;
    .button-container {
      &.MuiButtonBase-root.MuiButton-root {
        background-color: transparent;
        color: $bleu-de-france;
        border-bottom: 1px solid $bleu-de-france;
        font-weight: normal;
        margin-bottom: 8px;
      }
    }
  }
}
.chat-skeleton {
  margin-top: 30px;
}