.job-panel{
  .Jobs-box{
    &:first-child{
      margin: 0 0 16px 0 !important;
    }
  }

}
.Jobs-box {
  background-color: $doctor;
  @include big-black-shadow();
  display: flex;
  align-items: center;
  height: 96px;
  overflow: hidden;
  margin: 16px 20px;
  padding: 16px;
  border-radius: 5px;
}
.job-box-left{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 16px;
}