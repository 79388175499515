.snackbar-container {
	width: 100%;
	height: 40px;
	bottom: 56px !important;
	background: $gunmetal;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: $doctor;
	font-size: 11px;
	font-weight: bold;
	cursor: pointer;
	.snackbar-body {
		width: 90%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 8px;
		img {
			margin-right: 4px;
			width: 16px;
		}
	}
	.snackbar-corner {
		width: 5%;
		img {
			height: 16px;
		}
	}
}

.snackbar-sticky {
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-webkit-box-ordinal-group: 1;
	position: sticky !important;
	z-index: 1;
}

.snackbar-absolute {
	position: absolute;
}

@media (min-width: 770px) {
	.snackbar-container {
		max-width: 414px;
	}
}