.payment-method-wrapper {
  margin: 20px;
  .payment-method-title {
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: $charcoal;
    margin-left: 8px;
  }
  .payment-method-body {
    background-color: white;
    border-radius: 8px;
    padding: 0 8px;
    margin-top: 4px;
    padding-top: 16px;
    border: 1px solid #FFF;
    .saved-place-tag {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $spanish-gray;
    }
    .payment-method-type {
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        color: $charcoal;
      }
      .negative-color {
        color: $bittersweet;
      }
    }
    .balance-warning {
      display: flex;
      width: 100%;
      background: rgba(252, 106, 84, 0.05);
      border-radius: 4px;
      margin: 4px 0;
      padding: 4px;
      .balance-warning-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $bittersweet;
        padding-left: 4px;
      }
    }
    .payment-method-description {
      margin-top: 4.5px;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: $spanish-gray;
    }
    .dashed-spliter {
      border: 0.3px dashed $grainsboro;
      margin: 16px 0;
    }
    .delivering-to-actions {
      margin-top: 16px;
      border-top: 0.3px dashed $grainsboro;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      button {
        background: none;
        border: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $bleu-de-france;
        width: 50%;
        height: 100%;
      }
    }
    .no-payment-method {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        padding-top: 15px;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $silver-chalice;
      }
    }
  }
  .blinking {
    border-bottom: 1px solid #FFF;
    animation-name: blinking;
    animation-duration: 1s;
    animation-iteration-count: 100;
  }
  @keyframes blinking {
    50% {
      border-color: $bittersweet;
    }
  }
}