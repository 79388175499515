.mk-item-container {
    background-color: $doctor;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 8px 12px rgba(24, 26, 30, 0.12);
    margin-bottom: 16px;
    .mk-item-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            color: $charcoal;
            margin-bottom: 0
        }
        &.call-to-action {
            position: relative;
            padding: 0;
            .button-container {
                width: 49%;
                &.reject-send-mk {
                    &.button-outline .MuiButtonBase-root.MuiButton-root {
                        color: $cinnabar;
                        border-color: $cinnabar;
                    }
                }
            }
        }
        .state {
            border-radius: 4px;
            padding: 0 4px;
            color: $doctor;
            font-size: 10px;
            &.pending {
                background-color: $tangerine;
            }
            &.accepted,
            &.transferred {
                background-color: $emerald;
            }
            &.rejected {
                background-color: $cinnabar;
            }
        }
        .mk-item-row-inner {
            display: flex;
            align-items: center;
            .view-note {
                border-left: 1px solid $grainsboro !important;
                margin-left: 8px;
                &.button-container.MuiButtonBase-root.MuiButton-root {
                    padding: 0 0 0 8px;
                    background-color: transparent;
                    color: $bleu-de-france;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 19px;
                    border: 0;
                    border-radius: 0;;
                }
            }
        }
    }
}
.transfer-mk-note-bottom-sheet {
    padding: 20px;
    p {
        margin: 20px 0;
        text-align: left;
        overflow-wrap: break-word;
    }
}
.confirmation-bottom-sheet .bottom-sheet-info .call-to-action {
    position: relative;
    padding: 0;
}