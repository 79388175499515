.app-rating .Modal {
    display: flex;
    background-color: rgba(110, 110, 110, 0.55);
}
.app-rating .Modal .modal-dialog {
    display: flex;
    margin: 0;
    position: absolute;     
    bottom: 0;
    transform: translate(-50%,0);
    right: 50%;
    left: 50%;
    max-width: 412px;
    width: 100%;
}
.app-rating .Modal .modal-content {
    padding: 15px;
    border-radius: 30px 30px 0 0;
    box-shadow: none;
    border: 0;
}
.app-rating .Modal .modal-body-title {
    display: flex;
    padding: 10px;
}
.app-rating .text-display {
    color: black;
    font-weight: bold;
}
.app-rating .Modal .modal-kuya-container {
    
    flex-basis: 30%;
}
.app-rating .Modal .modal-kuya-container img {
    margin-right: 10px;
    height: 70px;
    width: 100%;
}
.app-rating .title {
    flex-basis: 70%;
}
.app-rating .app-rating-title {
    margin:0;
    font-size: 18px;
    /* font-family: 'lato_black'; */
}
.app-rating .app-rating-body p {
    color: #646464;
}
.app-rating .app-rating-button-container {
    padding: 0 10px;
}
.app-rating .primary-button{
    text-transform: none;
    color: #fff;
    background-color: #0C87FA;
    outline: none;
    box-shadow: none;
    border: none;
    width: 100%;
    border-radius: 5px;
    padding: 4px;
    font-size: 15px;
    border: 1px solid #0C87FA !important;
    font-weight: bold;
}
.app-rating .secondary-button{
    text-transform: none;
    border: 0 !important;
    color: #0C87FA;
    outline: none;
    box-shadow: none;
    border: none;
    width: 100%;
    border-radius: 5px;
    padding: 4px;
    font-size: 15px;
    font-weight: bold;
}
.app-rating .modal-close {
    position: absolute;
    right: 20px;
    opacity: .6;
}