.job-locations-container {
    padding-top: 8px;
    .icon-container {
        position: relative;
        .number {
            position: absolute;
            @extend .overline;
            color: $silver-chalice;
            bottom: 5px;
            right: 1px;
        }
    }
    .review-booking-item-inner {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}