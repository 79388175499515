.booking-special {
  padding: 40px 20px 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 120px;

  .booking-special-title p {
    margin-bottom: 24px;
    @extend .subtitle1;
    color: $charcoal;
  }

  .booking-special-textarea {
      &.form-group-container {
          border-bottom: 1px solid $grainsboro;
          padding: 0 8px;
      }
      label {
          color: $gunmetal;
          font-size: 12px;
          line-height: 18px;
          font-weight: normal;
      }
      textarea {
        width: 100%;
        background-color: $doctor;
        border: 0;
        padding: 0;
        &:focus {
            outline: none;
        }
        &::placeholder {
            @extend .body2;
            color: $grainsboro;
        }
        &::-webkit-scrollbar {
          width: 0;
        }
        &:placeholder, &:-moz-placeholder, &:-ms-input-placeholder, &::-webkit-input-placeholder {
            @extend .body2;
            color: $grainsboro;
        }
    }
  }
}
.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: inherit;
  padding: 1px 6px;
}