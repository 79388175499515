.login-bottom-sheet-container {
    padding: 20px;
    background-color: $doctor;
    &.check-code {
        .enter-code-description {
            padding: 0;
            .back-icon {
                margin: 10px 0;
            }
        }
        .enter-code-inputs {
            padding: 0;
        }
        .receive {
            padding: 20px 0;
        }
    }
    .welcome-being-mykuya {
        position: relative;
        padding: 0;
        bottom: 0;
        margin-top: 50px;
        .text-info {
            color: $silver-chalice;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
        }
    }
    .verification-loading {
        display: flex;
    }
    .enter-phone-number-container {
        padding: 25px 0;
        .error {
            color: $cinnabar;
            @extend .overline;
            margin-bottom: 0;
        }
    }
    .enter-phone-number {
        display: flex;
        width: 100%;   
        .country-code {
            flex-basis: 25%;
            max-width: 25%;
            padding-right: 8px;
            .form-group-container {
                padding: 5px;
                @media (min-width: 400px) {
                    padding: 5px 8px;
                }
            }
            label {
                color: $silver-chalice;
            }
            .welcome-select-country {
                background-color: $doctor;
                width: 100%;
                border-radius: 8px;
                outline: none;
                -webkit-appearance: none;
                text-align: center;
                border: none;
                padding: 4px 0;
                @media (min-width: 400px) {
                    padding: 4px 8px 4px 0;
                }
            }
            .welcome-select-country:-moz-focusring {
                color: transparent;
                text-shadow: 0 0 0 $doctor;
            }
            .welcome-select-country-firefox {
                padding-left: 0 !important;
            }
        }
        .phone-number {
            flex-basis: 75%;
            max-width: 75%;
            padding-left: 8px;
            .phone-number-container {
                padding: 5px 8px;
                input {
                    width: 100%;
                    padding: 0;
                    color: $eerie-black;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                    &::placeholder {
                        color: $silver-chalice;
                    }
                }
            }
        }
    }
}