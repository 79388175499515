.Jobs-box-heading-title{
  margin-top: 4px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  .title{
    font-weight: bold;
    color: #3B424A;
    font-size: 13px;

  }
  .address{
    font-size: 12px;
    font-weight: 300;
    color: #3B424A;
  }
}

.Jobs-box-heading-startdate{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Status_State{
  margin: 0 !important;
}