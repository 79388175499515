.payment-method-bottom-sheet {
    padding-bottom: 24px;
    background-color: $doctor;
    .title-bar-container {
        border-radius: 20px 20px 0 0;
        top: 20px;
    }
    .Sheet_Content {
        width: 100%;
    }
    .payment-methods-container {
        padding-top: 85px;
    }
    .tip {
        color: $spanish-gray;
        @extend .caption;
        padding: 0 20px;
        margin: 0;
        background-color: $doctor;
    }
    .billing-information-add {
        padding: 0 20px 24px;
    }
    .mykoin-inner {
        .billing-information-card-item-name {
            display: flex;
            align-items: center;
            .lozenge-container {
                padding-left: 8px;
            }
        }
    }
}
.payment-methods-container {
    width: 100%;
}
.billing-information-card-item-value {
    display: flex;
    justify-content: space-between;
    .top-up-mykoin-container {
        display: flex;
        p {
            font-size: 13px;
            color: $silver-chalice;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            margin-left: 5px;
            svg {
                width: 8px;
                height: 10px;
                margin-left: 6px;
            }
        }
        span {
            font-size: 12px;
            color: $bittersweet;
        }
    }
}
.review-booking-item-container {
    &.mykoin {
        .mykoin-image {
            width: 16px;
            height: 16px;
        }
    }
}