.Job_Information_Partner{
  margin-top: 36px;
  .Section_1{
    display: flex;
    padding: 0 16px;
    .Avatar {
      width: 48px;
      height: 48px;
      img {
        width: 48px;
        height: 48px;
        display: inherit;
      }
    }
  }
  .Job_Information_Section1_Right_Box{
    padding-left: 16px;
  }
  .Partner_Name{
    color: $charcoal;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.15px;
    margin: 0;
  }
  .Co_Partner_Name{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $spanish-gray;
    letter-spacing: 0.4px;
    margin: 0;
  }
  .Partner_Star{
    width: 15px;
    height: 15px;
    margin-bottom: 5px;
    margin-left: 10px;
  }
  .Partner_Star{
    display: inline-block;
    font-size: 18px;
    margin-left: 5px;
  }
}
.Job_Information_Thumb {
  display: flex;
  align-items: center;
  padding: 20px 32px 8px;
  .Img_Box {
    width: 24px;
    height: 24px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.Job_Information_Rate {
  display: flex;
  align-items: center;
  padding: 0 32px;
  margin-bottom: 8px;
}
.thumbs-title {
  margin: 0 0 0 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: $charcoal;
  letter-spacing: 0.25px;
}
.job-comment {
  padding: 20px 24px;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.25px;
  color: $charcoal;
}