.job-matching-circle-ripple {
    position: relative;
    padding-top: 65px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    .circle-ripple {
        background-color: #0787fa;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        -webkit-animation: ripple 0.7s linear infinite;
        animation: ripple 2s linear infinite;
        /* position: fixed; */
        // display: none;
    }
}

@-webkit-keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(12, 135, 250, 0.3), 0 0 0 1em rgba(12, 135, 250, 0.3), 0 0 0 3em rgba(12, 135, 250, 0.3), 0 0 0 5em rgba(12, 135, 250, 0.3);
    }

    100% {
        box-shadow: 0 0 0 1em rgba(12, 135, 250, 0.3), 0 0 0 3em rgba(12, 135, 250, 0.3), 0 0 0 5em rgba(12, 135, 250, 0.3), 0 0 0 8em rgba(12, 135, 250, 0);
    }
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(12, 135, 250, 0.3), 0 0 0 1em rgba(12, 135, 250, 0.3), 0 0 0 3em rgba(12, 135, 250, 0.3), 0 0 0 5em rgba(12, 135, 250, 0.3);
    }

    100% {
        box-shadow: 0 0 0 1em rgba(12, 135, 250, 0.3), 0 0 0 3em rgba(12, 135, 250, 0.3), 0 0 0 5em rgba(12, 135, 250, 0.3), 0 0 0 8em rgba(12, 135, 250, 0);
    }
}