.edit-profile {
    .title-bar-container {
        p {
            @extend .subtitle1;
        }
    }
    .title {
        color: $charcoal;
        padding: 20px 20px 0; 
        @extend .h6;
        margin: 0 0 16px;
    }
}