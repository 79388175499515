.Jobs-box-heading-date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: $spanish-gray;
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
  span:nth-child(1){
    padding: 0 4px 0 0;
  }
  .job-price {
    display: flex;
    align-items: center;
    .dot-divider {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color:$spanish-gray;
      margin: 0 4px;
    }
  }
}