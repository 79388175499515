.card-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0;
    }
    .icon-container {
        width: 25px;
        .mykoin {
            width: 16px;
            height: 16px;
            margin: 0 auto;
            max-width: 100%;
            display: block;
        }
        .mc, .visa, .gcash {
            max-width: 100%;
            height: auto;
            display: block;
        }
        .mc {
            width: 25px;
            margin: 0 auto;
        }
    }
    p {
        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0.25px;
        color: $charcoal;
        margin-bottom: 0;
        padding-left: 8px;
    }
}
.job-receipt-payment-method-container {
    padding: 0 16px;
    margin-bottom: 8px;
    .job-receipt-payment-method {
        background-color: #ECF4FA;
        border-radius: 4px;
        padding: 8px;
    }
}