.add-xendit-card {
    padding: 65px 20px;
    background-color: $doctor;
    .tip {
        @extend .caption;
        color: $spanish-gray;
        margin-bottom: 0;
    }
    .title {
        @extend .h6;
        color: $charcoal;
        margin-bottom: 0;
    }
    .adyen-checkout__button {
        @extend .button;
        background-color: $bleu-de-france;
    }
    .form-wrapper {
        form {
            input {
                border-bottom: 1px solid #dbdbdb;
                box-shadow: none;
                outline: none;
                border-top: none;
                border-left: none;
                border-right: none;
                border-radius: 0px;
                width: 100%;
                caret-color: #0488FA;
                color: rgb(0, 17, 44);
                font-size: 16px;
                font-weight: 400;

                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #DCDCDC;
                    opacity: 1;

                    /* Firefox */
                    /* font-family: 'lato_regular'; */
                    font-size: 16px;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #DCDCDC;

                    /* font-family: 'lato_regular'; */
                    font-size: 16px;
                }

                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: #DCDCDC;

                    /* font-family: 'lato_regular'; */
                    font-size: 16px;
                }
            }

            label {
                margin-bottom: 4px !important;
                color: #00112c;
                display: block;
                font-size: .81em;
                font-weight: 400;
                line-height: 13px;
                padding-bottom: 5px;

                /* font-family: 'lato_black'; */
            }

            input {
                &.invalid {
                    border-bottom: 1px solid red;
                }

                &.valid {
                    /* border-bottom: 1px solid green; */
                }
            }

            .label-text-error {
                color: #d10244;
                font-size: .75em;
                font-weight: 400;
                margin-top: 4px;
            }

            .field-batch {
                display: flex;
                gap: 24px;
                margin-top: 24px;
            }

            .field-wrapper {
                margin-top: 0px;
                .input-container {
                    display: flex;
                    position: relative;
                    img {
                        position: absolute;
                        right: 8px;
                        bottom: 8px;
                    }
                    .card-number-valid {
                        width: 24px;
                    }
                }
            }
            .name-on-card {
                margin-top: 40px;
            }
        }
    }
    .adyen-checkout__button {
        margin-top: 48px;
    }
}