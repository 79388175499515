.spinner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 20px;
}
.pulse-container {
  width: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pulse-bubble {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #aaa;
}

.pulse-bubble-1 {
    animation: pulse .6s ease 0s infinite alternate;
}
.pulse-bubble-2 {
    animation: pulse .6s ease .3s infinite alternate;
}
.pulse-bubble-3 {
    animation: pulse .6s ease .6s infinite alternate;
}
@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .25;
    transform: scale(.75);
  }
}