.Header-back .Header-back-img {
    background-color: #FAFBFC;
    padding: 18px 15px;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}
.Header-back .Header-back-img svg {
    width: 15px;
}
.Header-back .Header-back-img p {
    margin: 0 auto;
    font-size: 15px;
    color: #000;
    font-weight: bold;
}