.add-transaction-container {
    .title-bar-container { 
        justify-content: flex-start;
        p {
            text-align: right;
            margin: 0;
            padding-left: 24px;
            @extend .subtitle1;
        }
    }

    .add-transaction {
        padding: 80px 20px 70px;
        .tabs-menu-segment {
            margin: 0;
            padding: 0;
            .item-segment {
                flex-basis: 50%;
                border-color: $bleu-de-france;
                color: $bleu-de-france;
                line-height: 24px;
                cursor: pointer;
                overflow: hidden;
                &::marker {
                    font-size: 0;
                }
            }
            .segment-active {
                background-color: $bleu-de-france !important;
                color: $doctor;
                height: 26px;
            }
        }
        .tabs-panel-container {
            padding: 16px 0;
            .current-payment-methods-wrapper {
                display: flex;
                margin: 5px 0 16px;
                .current-payment {
                    flex: 1 1 50%;
                    max-width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    &:first-child {
                        border-right: 1px solid $grainsboro;
                    }
                    .icon {
                        width: 24px;
                        height: 24px;
                        img {
                            max-width: 100%;
                        }
                    }
                    p {
                        color: $spanish-gray;
                        @extend .subtitle;
                        margin: 5px 0;
                    }
                }
            }
            .hint {
                border-top: 1px solid $grainsboro;
                padding: 8px 0 24px;
                p {
                    color: $spanish-gray;
                    @extend .subtitle;
                    margin: 0;
                }
            }
        }
        .mk-form {
            p {
                @extend .body2;
                color: $charcoal;
                margin: 24px 0 16px;
            }
        }
        .form-group-container {
            label {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
            }
        }
        textarea.form-control {
            height: auto;
            &::placeholder {
                color: $grainsboro;
                @extend .body2;
            }
        }
        .form-control {
            &::placeholder {
                color: $grainsboro;
                @extend .body2;
            }
        }
    }

    .call-to-action {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        @media (min-width: 770px) {
            max-width: 412px;
        }
        .button-container.MuiButtonBase-root.MuiButton-root {
            padding: 8px; 
        }
    }
}