.location-search-delete-button {
    padding: 0;
    outline: none;
    box-shadow: none;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    svg {
        width: 35px;
        height: 22px;
    }
}