.select-city-container {
    .select-city-loading {
        position: absolute;
        top: 50%;
        right: 50%;
    }
    .cities-container {
        padding: 75px 20px 20px;
        .city-item {
            border-bottom: 1px solid $grainsboro;
            padding: 10px 0;
            cursor: pointer;
            .container-highlight {
                span {
                    color: $spanish-gray;
                }
            }
            span {
                margin: 0;
                font-size: 15px;
                line-height: 20px;
                color: $gunmetal;
                font-weight: 400;
            }
        }
    }
}
.Bottom_Sheet.select-city-bottom-sheet {
    background-color: $doctor;
    border-radius: 20px 20px 0 0;
    min-height: 450px;
}
.select-city-search-input-container {
    padding: 20px 20px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    background-color: $doctor;
    border-radius: 20px 20px 0 0;
    .search-input-form {
        display: flex;
        position: relative;
        align-items: center;
        margin: 0;
        flex: 1;
        .Dynamic_Parent_Icon {
            position: absolute;
            left: 8px;
        }
        .search-input {
            border-radius: 8px;
            padding: 8px 8px 8px 36px;
            height: 40px;
            &:focus {
                box-shadow: none;
            }
        }
        .clear-icon {
            position: absolute;
            right: 2px;
            width: 20px;
            height: 20px;
        }
    }
    .cancel-button-container {
        .button-container.MuiButtonBase-root.MuiButton-root {
            background-color: transparent;
            color: $bleu-de-france;
            border: 0;
            font-weight: 600;
            font-size: 13px;
            padding: 8px;
        }
    }
}