.tabbar-wrapper {
  height: 56px;
  width:100%;
  bottom: 0;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.08);
  background: white;
}
.tabbar-sticky {
  position: sticky;
}
.tabbar-absolute {
  position: absolute;
}
.tabbar-fixed {
  position: fixed;
  z-index: 1;
}
.tabbar-container {
  width: 100%;
  height: 100%;
  display: flex;
}
.tabbar-btn {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  background: none;
  border: none;
}
.tabbar-text {
  color: #898E92;
  font-size: 12px;
  letter-spacing: -0.02em;
}
[aria-selected='true'] {
  color: #0C87FA;
}

.tabbar-img-selected {
  filter: invert(34%) sepia(82%) saturate(2199%) hue-rotate(195deg) brightness(103%) contrast(96%);
}
@media (min-width: 770px) {
  .tabbar-fixed  {
    max-width: 414px;
  }
}