.real-price {
    text-decoration: line-through;
}
.new-price {
    font-weight: 600;
}
.applide-promo-code {
    display: flex;
    padding: 0 5px;
    color: $aero;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.4px;
    p {
        margin: 0;
    }
    .static-text {
        margin-left: 3px;
    }
}