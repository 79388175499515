.booking-button-container {
    // position: fixed;
    background-color: $doctor;
    padding: 0 20px 20px;
    bottom: 0;
    width: 100%;
    .button-container.MuiButtonBase-root.MuiButton-root {
        width: 100%;
        padding: 8px;
    }
    .booking-button-icon {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 16px;
        .icon {
            position: absolute;
            top: -6px;
            right: 3px;
        }
    }
    .cancel-booking {
        &.MuiButtonBase-root.MuiButton-root {
            color: $bittersweet;
            margin-top: 8px;
            background-color: $doctor;
            box-shadow: none;
            border: 0;
            &:focus {
                box-shadow: none;
            }
        }
    }
    .schedule-booking-button {
        margin: 8px 0 0;
        p {
            margin: 0;
        }
        svg {
            width: 25px;
            height: 25px;
            margin-right: 5px;
        }
    }
}