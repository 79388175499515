.Home_Featured{
  display: flex;
  flex-wrap: wrap;
  .Home_Featured_Box{
    flex: 1 1 100%;
    width: 100%;
    overflow-x: hidden;
    .tab-active-home {
      h5 {
        @extend .h6;
        color: $doctor;
        &:focus-visible {
          outline: none;
        }
      }
    }
    .featured-container {
      display: flex;
      flex-wrap: wrap;
    }
    .Home_Featured_Box_Service{
      text-align: center;
      flex: 1 1 33.3333%;
      width: 33.3333%;
      max-width: 33.3333%;
      display: inline-flex;
      justify-content: center;
      button{
        padding: 0;
        min-width: unset;
        font-size: unset;
        text-transform: none;
        width: 100%;
      }

    }
  }
  .tab-item-history{
    margin: 0 12px !important;
    &:focus-visible {
      outline: none;
    }
    div, h5 {
      &:focus-visible {
        outline: none;
      }
    }
    &:first-child{
      margin-left:24px !important;
    }
  }
  .category-container {
    &.slick-initialized {
      .slick-list {
        padding: 0 !important;
      }
      .slick-slide {
        padding: 0 0 0 20px;
        div {
          &:focus-visible {
            outline: none;
          }
        }
        h3 {
          @extend .h6;
          color: $aero;
          font-size: 19px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0.15px;
          margin: 16px 0 24px;
          &:focus-visible {
            outline: none;
          }
        }
        &.slick-active {
          h3 {
            color: $doctor;
            &:focus-visible {
              outline: none;
            }
          }
        }
      }
    }
  }
}
.Featured_Title{
  padding: 0 0 8px;
  color: $doctor;
  margin: 4px 0 10px 0;
  @extend .caption;
  font-size: 12px;
  letter-spacing: 0.4px;
  text-align: center;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Tabs_Menu_Parent{
  &:focus-visible {
    outline: none;
  }
  .tabs-menu-tab{
    &:focus-visible {
      outline: none;
    }
    .tab-item-history:last-child{
      padding-right: 20px;
    }
  }
}