.Tutorial .slick-dots{
  bottom: 25px !important;
}
.Tutorial .slide{
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  width: 100%;
  height: 100vh !important;
  background-size: cover !important;
  position: relative;
}
.Tutorial .slide .step-svg{
  /* width: 60%; */
  margin: auto auto 16px auto;
}
.Tutorial .slick-dots li.slick-active button:before {
  color: #FF9012;
  font-size: 12px;
}
.Tutorial .slick-dots li button:before {
  color: #FF9012;
  font-size: 12px;
}
.Tutorial .text {
  text-align: center;
  color: white;
  font-weight: bold;
}
.Tutorial .text .title{
  font-size: 24px;
  margin: 0 0 16px 0;
  font-weight: 900;
  font-family: 'Poppins';
}
.Tutorial .text .description{
  font-size: 14px;
  font-weight: 200;
  line-height: 22px;
  margin: 0 50px 32px 50px;
}
.Tutorial .next-tutorial{
  position: fixed;
  right: 5px;
  bottom: 24px;
  z-index: 10;
  outline: none;
  box-shadow: none;
  border: none;
  background: transparent;
  color: white;
  font-size: 16px;
}
.Tutorial .skip-tutorial{
  position: fixed;
  left: 5px;
  bottom: 24px;
  z-index: 10;
  outline: none;
  box-shadow: none;
  border: none;
  background: transparent;
  color: white;
  font-size: 16px;
}
.Slide_Box_Img{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 1 1 100%;
}

.Text_Box{
  width: 100%;
  min-height: 100px;
}