.billing-information-card-item {
    &.GCash {
        border-left: 8px solid $vivid-sky-blue;
        height: 85px;
        .billing-information-card-item-img {
            width: 80px;
            @media (max-width: 330px) {
                width: 65px;
            }
            img {
                display: block;
                max-width: 100%;
                height: auto;
                width: auto;
            }
        }
        .billing-information-card-item-first-row {
            .billing-information-card-item-info-inner {
                display: flex;
                align-items: center;
            }
        }
        .billing-information-card-item-value {
            .connect-to-gcash-wallet {
                margin: 0;
                display: flex;
                align-items: center;
                font-size: 13px;
                color: $silver-chalice;
                display: flex;
                align-items: center;
                margin-bottom: 0;
                margin-left: 2px;
                cursor: pointer;
                svg {
                    width: 8px;
                    height: 10px;
                    margin-left: 6px;
                }
            }
        }
        .billing-information-card-expiration {
            display: flex;
            align-items: center;
            p {
                margin-bottom: 0;
                padding-left: 8px;
                color: $spanish-green;
                @extend .body2;
            }
        }
    }
}
.gcash-bottom-sheet {
    .billing-add-card {
        background-color: $doctor;
    }
    .add-gcash-container,
    .verifying-gcash-container {
        background-color: $doctor;
        padding: 24px 20px;
        .title {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 19px;
            text-align: center;
            margin: 0 0 24px;
        }
        .body {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: $spanish-gray;
            text-align: left;
        }
        .verify-gcash-btn {
            width: 100%;
        }
        .cta-gcash {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
            .nevermind-add-gcash {
                width: 49%;
            }
            .adyen-checkout__button {
                background-color: $bleu-de-france;
                width: 49%;
                border-radius: 5px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                padding: 8px;
                height: 44px;
                &.adyen-checkout__button--pay {
                    margin-top: 0;
                }
            }
        }
    }
    .gcash-button-loading {
        .white-fading-circle-loading {
            width: 27px;
            height: 27px;
        }
    }
}