.visual-picker-container {
  background-color: $doctor;
  border-radius: 8px;
  padding: 10px 15px;
  display: flex;
  .visual-picker-inner {
    display: flex;
    width: 100%;
    align-items: center;
    &.no-title {
      justify-content: center;
    }
    .icon {
      width: 16px;
      height: 16px;
      margin-right: 15px;
      display: flex;
      img {
        width: 100%;
      }
    }
    .visual-picker-title {
      p {
        margin-bottom: 0;
      }
      .title {
        color: $charcoal;
        font-size: 13px;
      }
      .sub-title {
        color: $spanish-gray;
        font-size: 12px;
      }
    }
  }
}