.rce-container-mbox {
  display: block;
  overflow: hidden;
  min-width: 200px;
  .timezone {
    display: flex;
    color: $spanish-gray;
    @extend .assistive;
    margin: 0 20px 12px 20px;
  }
  .rce-mbox-text  {
    font-size: 13px;
    word-break: break-word;
    white-space: pre-line;
    &.include-number {
      span {
        text-decoration: underline;
      }
    }
    p {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      margin: 0;
      color: $charcoal;
    }
  }

  &.client {
    display: flex;
    align-items: flex-end;
    flex-direction: column-reverse;
    &.image {
      .rce-mbox {
        background-color: transparent;
        .rce-mbox-body {
          padding: 0;
        }
      }
    }
    .rce-mbox {
      float: right;
      margin-left: 50px;
      margin-right: 20px;
      background-color: $columbia-blue;
      color: $eerie-black;
      border-radius: 8px 8px 0px 8px;
      
      .rce-mbox-body {
        margin: 0;
        padding: 8px 16px 8px 8px;
        position: relative;
      }
    }
    .timezone {
      margin: 0 20px 12px 0;
    }
  }

  &.client,
  &.interactive-box-wrapper {
    .rce-mbox-time {
      color: $bleu-de-france;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      width: 12px;
      height: 12px;
      margin: 0;
      right: 5px;
      bottom: 5px;
    }
    .timezone {
      justify-content: flex-end;
    }
  }

  &.partner {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    &.image {
      .rce-mbox {
        background-color: transparent;
        .rce-mbox-body {
          padding: 0;
        }
      }
    }
    .rce-mbox {
      float: left;
      margin-right: 50px;
      margin-left: 20px;
      background-color: $bleu-de-france;
      color: $doctor;
      border-radius: 8px 8px 8px 0px;
      .rce-mbox-body {
        margin: 0;
        padding: 8px 16px 8px 8px;
        position: relative;
        span {
          color: $doctor;
        }
      }
    }
    .timezone {
      margin: 0 0 12px 20px;
    }
  }

  &.programmable_message {
    display: flex;
    flex-direction: column-reverse;
    .rce-mbox-photo {
      width: 100%;
      .rec-mbox-photo-pm {
        overflow: hidden;
        img {
          width: 100%;
          border-radius: 8px;
        }
      }
    }
    .timezone {
      display: flex;
      justify-content: flex-end;
      color: $spanish-gray;
      @extend .assistive;
    }
  }
}

.rce-mbox.message-focus {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-duration: 1s;
  animation-name: message-box-default-focus;
  animation-duration: 1s;
}
.rce-mbox-time.non-copiable:before {
  content: attr(data-text);
}

.rce-mbox-status {
  font-size: 12px;

  svg {
    overflow: visible;
  }
}

.interactive_request_result {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 16px;
  width: 100%;
  height: fit-content;
  background-color: #F5F5F5;
  border-radius: 8px;
  .title {
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: $charcoal;
  }
  .accepted {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.25px;
    color: $emerald;
  }
  .expired {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.25px;
    color: $spanish-gray;
  }
}