.T_F_P_Item{
  width: 25%;
  flex: 1 1 25%;
  display: flex;
  flex-direction: column;
  .T_F_P_I_Icon{
    flex: 1 1 100%;
    max-height: 32px;
    img{
      width: 32px;
      height: 32px;
      margin: 0 auto;
      border-radius: 100%;
      display: block;
    }
  }
  .T_F_P_I_Text{
    flex: 1;
    font-size: 16px;
    height: 36px;
    h5{
      font-size: 12px;
      text-align: center;
      font-weight: 400;
      line-height: 18px;
      margin: 0 4px;
      overflow: hidden;
      max-width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.Top_Four_Box{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
}