.rce-mbox-photo {
	.rce-mbox-text {
		padding: 5px 0px;
		max-width: 300px;
		margin: auto;
	}
}
.rce-mbox-photo--img {
	position: relative;
	display: flex;
	overflow: hidden;
	justify-content: center;
	border-radius: 5px;
	max-height: 300px;
	img {
		border-radius: 3px;
		max-height: 160px;
		min-height: 160px;
		user-select: none;
	}
	.rce-mbox-photo--img-loading {
		border-radius: 3px;
		max-height: 160px;
		min-height: 160px;
		min-width: 160px;
		user-select: none;
		.message-list-loading-container {
			top: 32%;
			width: 35%;
		}
	}
}
.rce-mbox-photo--img__block {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.5);
	border-radius: 5px;
	display: flex;
}
.rce-mbox-photo--img__block-item {
	margin: auto;
	cursor: pointer;
	width: 100px;
	height: 100px;
}
.photo-show-message {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.82);
	z-index: 100;
	display: flex;
	flex-direction: column;
	.close-photo {
		z-index: 2;
	}
	.close-io {
		font-size: 40px;
		color: $doctor;
	}
	.photo-show-message-image-box {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		z-index: 1;
	}
}
