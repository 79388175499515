.saved-places {
    background-color: $doctor;
    .saved-places-others {
        background-color: white;
        border-radius: 8px;
        margin-top: 16px;
        .saved-places-swipe-to-delete {
            &:last-child {
                border-bottom: 0;
            }
        }
        .saved-places-others-title {
            padding: 10px 15px;
            p {
                color: #676767;
                margin: 0;
            }
        }
        .saved-places-others-item {
            display: flex;
            align-items: center;
            padding: 16px 0 16px 16px;
            justify-content: space-between;
            cursor: pointer;
            .divider {
                display: flex;
                width: 100%;
                img {
                    margin-right: 15px;
                }
            }
            .name-address {
                .name {
                    margin: 0;
                    font-size: 14px;
                    color: #000;
                }
            }
        }
        :last-child {
            border-bottom: none;
        }
    }
    .action-icon {
        display: flex;
        svg path {
            fill: $spanish-gray;
        }
    }
    .saved-places-info {
        display: flex;
        align-items: center;
    }
    .saved-places-divider {
        border-bottom: 0.3px dashed $grainsboro;
        margin: 0 8px;
    }
}