.delivering-to-wrapper {
  margin: 24px 20px;
  .delivering-title {
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: $charcoal;
    margin-left: 8px;
  }
  .delivering-body {
    background-color: white;
    border-radius: 8px;
    padding: 0 8px;
    margin-top: 4px;
    padding-top: 16px;
    border: 1px solid #FFF;
    .saved-place-tag {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $spanish-gray;
    }
    .address-text {
      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 23px;
        color: $charcoal;
      }
      .no-address-warning {
        display: flex;
        width: 100%;
        background: rgba(252, 106, 84, 0.05);
        border-radius: 4px;
        margin: 4px 0;
        padding: 4px;
        .no-address-text {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: $bittersweet;
          padding-left: 4px;
        }
      }
    }
    .note-to-partner {
      display: flex;
      margin-top: 8px;
      .note-to-partner-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $spanish-gray;
        padding-left: 9px;
        word-break: break-all;
        &.note {
          display: -webkit-box;
          max-width: 318px;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 57px;
          text-overflow: ellipsis;
        }
      }
    }
    .delivering-to-actions {
      margin-top: 16px;
      border-top: 0.3px dashed $grainsboro;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      button {
        background: none;
        border: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $bleu-de-france;
        width: 50%;
        height: 100%;
      }
    }
  }
  .blinking {
    border-bottom: 1px solid #FFF;
    animation-name: blinking;
    animation-duration: 1s;
    animation-iteration-count: 100;
  }
  @keyframes blinking {
    50% {
      border-color: $bittersweet;
    }
  }
}