.mykoin-container {
    padding: 0 20px;
    .billing-information-card-item {
        border-left: 8px solid $liver-dogs;
        margin-top: 0;
    }
    .mykoin-inner {
        display: flex;
        justify-content: space-between;
        .billing-information-card-item-name {
            display: flex;
            align-items: center;
            .lozenge-container {
                padding-left: 8px;
            }
        }
    }
    .billing-information-card-item-value {
        display: flex;
        justify-content: space-between;
        .top-up-mykoin-container {
            display: flex;
            p {
                font-size: 13px;
                color: $silver-chalice;
                display: flex;
                align-items: center;
                margin-bottom: 0;
                margin-left: 2px;
                svg {
                    width: 8px;
                    height: 10px;
                    margin-left: 6px;
                }
            }
            span {
                font-size: 12px;
                color: $bittersweet;
            }
        }
    }
}