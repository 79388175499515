.billing-information-card-item {
  padding: 16px;
  height: 95px;
  background-color: $doctor;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
  margin: 24px 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.mc {
      border-left: 8px solid $sapphire;
  }

  &.visa {
      border-left: 8px solid $rufous;
  }

  .billing-information-card-item-img {
    .my-koin-svg {
      width: 16px;
      height: 16px;
    }
  }

  .billing-information-card-item-field {
    @extend .body2;
    display: flex;
    color: $charcoal;
    .billing-information-card-number {
        padding-left: 8px;
        margin-bottom: 0;
    }
  }

  .billing-information-card-item-name {
      @extend .subtitle2;
  }

  .billing-information-card-item-inner {
      display: flex;
      align-items: center;
  }

  .billing-information-card-expiration {
      display: flex;
      align-items: center;
      span {
          @extend .overline;
          color: $silver-chalice;
      }
      p {
          margin-bottom: 0;
          padding-left: 8px;
          color: $charcoal;
          @extend .body2;
      }
  }

  .billing-information-card-item-radio {
    padding-left: 8px;
  }

  .billing-information-card-item-first-row,
  .billing-information-card-item-second-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .billing-information-card-item-value {
    @extend .body2;
  }
}
.billing-card-image {
  &.mc {
    width: 25px;
    height: 16px;
  }
  &.visa {
    width: 32px;
    height: 17px;
  }
  &.GCash {
    width: 25px;
    height: 20px;
  }
}