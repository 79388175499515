.job-information-wrapper{
  width: 100%;
  height: auto;
  background: $doctor;
  
  .title-bar-container {
    position: relative;
  }
}
.Job_Information{
  flex: 0 0 100%;
  height: auto;
  background: $doctor;
  margin-top: 16px;
}
.job-completed-container {
  .title-bar-container {
    padding: 20px 20px 12px;
    p {
      padding: 0 12px;
      text-align: left;
      @extend .subtitle1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.job-information-container {
  padding-top: 54px;
  .tabs-menu-segment {
    margin: 0;
    padding: 10px 20px 20px;
    .item-segment {
      flex-basis: 50%;
      border-color: $bleu-de-france;
      color: $bleu-de-france;
      line-height: 24px;
      cursor: pointer;
      overflow: hidden;
      &::marker {
          font-size: 0;
      }
    }
    .segment-active {
      background-color: $bleu-de-france !important;
      color: $doctor;
      height: 26px;
    }
  }
}