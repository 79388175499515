.Job_Chat_History_Send {
  background-color: #0488FA !important;
  text-transform: none !important;
  border: none;
  box-shadow: none;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  font-size: 20px;
  outline: none;
  padding: 0;
  min-width: unset;
  margin-left: 8px;
}

.Job-chat-history-emoji-button {
  border: none;
  width: 24px;
  height: 24px;
  outline: none;
  padding: 0;
  // max-height: 20px;
  background: $doctor;
}

.Job-chat-history .Job-chat-history-emoji-button.IoMdAttach svg {
  margin-top: 6px;
  transform: rotate(-45deg);
}

.Job-chat-history .Job-chat-history-recorder.IoIosMic {
  transform: scale(1.9);
}

.Job-chat-history .Job-chat-history-recorder-IoIosCheckmark {
  background-color: #39D139 !important;
}

.Job-chat-history .Job-chat-history-recorder-IoIosClose {
  background-color: red !important;
}

.Job-chat-history .Job-chat-history-recorder svg.IoMdSend {
  margin-top: 5px;
  margin-right: -3px;
}

.Job-chat-history .Job-chat-history-recorder svg.IoIosSquare {
  margin-top: 5px;
  margin-right: -1px;
}

.Job-chat-history .Job-chat-history-recorder svg.IoIosMic {
  margin-top: -0.5px;
  margin-right: 0.5px;
}

.Job-chat-history .Job-chat-history-recorder svg.IoIosCheckmark {
  font-size: 30px;
}

.Job-chat-history .Job-chat-history-recorder svg.IoIosClose {
  font-size: 30px;
}

.Job-chat-history .Job-chat-history-recorder .MuiButton-label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Job-chat-history .Job-chat-history-recorder:disabled {
  background-color: #fff;
}

.Job-chat-history .Job-chat-history-record canvas {
  width: 100%;
  height: 30px;
  border-radius: 40px;
}

.Job-chat-history .Job-chat-history-input-container {
  // min-height: 64px;
  order: 3;
  position: relative;
  flex: none;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 0;
  background: $doctor;
  bottom: 0;
  border-top: 1px solid $grainsboro;
  margin-top: 16px;
}

.Job-chat-history .Job-chat-history-input-container .Job-chat-history-input {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  min-height: 35px;
  position: relative;
  z-index: 2;
  width: 100%;
}

.Job-chat-history .Job-chat-history-input-and-actions {
  background-color: $doctor;
  border-radius: 8px;
  padding: 0 8px 0 0;
  overflow: hidden;
  border: 1px solid #81B7E9;
}

.Job-chat-history-actions {
  display: inline-block;
}

.Job-chat-history-action-width {
  width: 10%;
}

.Job-chat-history-input-width {
  flex: 1 1 85%;
  width: 85%;
}

.Job-chat-history-input-width-longer {
  flex: 1 1 85% !important;
  width: 85% !important;

}

.Job-chat-history .Job-chat-history-user-actions canvas {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  vertical-align: middle;
}

.Job-chat-history .Job-chat-history-user-actions-textarea {
  width: auto;
  flex-basis: 85%;
  padding: 0 0 0 5px;
}

.Job-chat-history .Job-chat-history-user-actions-textarea-record {
  flex: 1 1 100% !important;
  width: 100% !important;
}

.Job-chat-history #job-chat-history-timer svg {
  margin-top: 2px;
  font-size: 20px;
}

//.Job-chat-history #job-chat-history-timer svg path {
//  fill: #FAFBFC;
//}

.Job-chat-history #job-chat-history-timer .values {
  color: #FAFBFC;
  font-weight: 400;
  font-size: 15px;
}

.Job-chat-history #job-chat-history-timer .recording p {
  margin: 0;
  color: black;
  font-weight: 500;
  font-size: 18px;
}

.Job-chat-history .Job-chat-history-user-actions-recorder {
  width: auto;
  flex-basis: 15%;
  align-items: center;
  margin-left: 4px;
}

.Job-chat-history .emoji-mart {
  width: 100% !important;
  margin-top: 2px;
}

.Job-chat-history .Job-chat-history-show {
  display: block;
}

.Job-chat-history .Job-chat-history-hide {
  display: none;
}

.Job-chat-history-action-parent {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 12px;
  .J_C_H_A_P_Right {
    flex: 1 1 85%;
    width: 85%;
    .Left_Icons_Text_Area {
      display: flex;
      .Job_Chat_History_Input_And_Actions {
        // flex: 1 1 60%;
        // width: 60%;
      }
      .L_I_T_A_Icons {
        // flex: 1 1 40%;
        // width: 40%;
        display: flex;
        align-items: center;
        .Left_Icons_Parent {
          display: flex;
          align-items: center;
          .Left_Icons_Child_Box {
            // flex: 1 1 33%;
            // width: 33%;
            margin-right: 8px;
          }
        }
      }
      .L_I_T_A_Icons_Focus {
        // flex: 1 1 40% !important;
        // width: 40% !important;
      }
      .L_I_T_A_Icons_Blur {
        // flex: 1 1 15% !important;
        // width: 15% !important;
      }
    }
  }
  .J_C_H_A_P_Left {
    // flex: 1 0 15%;
    // width: 15%;
    display: flex;
    align-items: center;
  }
}

.Camera_Chat_History, .Recorder_Chat_History .Blue_Left_History {
  width: 24px;
  height: 24px;
}

.White_Up_Arrow {
  margin: 4px;
}