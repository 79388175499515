.chat-with-support-bottom-sheet {
    .Sheet_Content {
        width: 100%;
        background-color: $doctor;
        padding: 30px 20px 20px;
        border-radius: 20px 20px 0 0;
    }
    .img-container {
        img {
            max-width: 100%;
            height: auto;
            display: block;
        }
    }
    .header {
        @extend .h6;
        color: $charcoal;
        text-align: center;
        margin: 24px 0 8px;
    }
    .content {
        @extend .caption;
        color: $charcoal;
        text-align: center;
    }
    .code-content {
        color: $bittersweet;
        @extend .caption;
    }
    .chat-with-support {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 8px;
        border-radius: 5px;
        margin-top: 16px;
        background-color: $bleu-de-france;
        color: $doctor;
        span {
            padding-left: 8px;
            color: $doctor;
            @extend .button;
        }
    }
}