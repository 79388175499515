.Location-search-spacer-booking {
    background-color: white;
}
.Location-search-spacer-booking .Spacer-icon{
    width: 2px;
}
.Location-search-spacer-booking .Location-search-spacer-div{
    padding: 0 6px;
}
.Location-search-spacer-booking .Location-search-booking-divider hr{
    margin: 10px 0;
}
.Location-box.jobs-box-spacer img {
    width: 3px;
    margin-bottom: 2px;
}
.Location-box.jobs-box-spacer {
    padding-left: 0;
    background-color: #fff;
    border-radius: 0;
    display: flex;
    align-items: center;
}
.Location-box.jobs-box-spacer .Location-search-booking-divider {
    padding-left: 40px;
    position: absolute;
    padding-right: 15px;
    width: 100%;
}
.Location-box.jobs-box-spacer .spacer-img {
    position: absolute;
    top: -5px;
    left: 21px;
}
.Location-box .Location-search-booking-divider {
    width: 100%;
    padding-left: 22px;
    position: absolute;
    padding-right: 30px;
}
.Location-box .Location-search-booking-divider hr{
    margin: 6px 0;
}
@media (max-width: 405px) {
    .Location-box.jobs-box-spacer .Location-search-booking-divider {
        width: 93%;
    }
}