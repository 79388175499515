.tab-container {
  border-radius: 100px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  .tab {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3px 12px;
    cursor: pointer;
    user-select: none;
    &:hover {
      background-color: $alice-blue;
    }
  }
  .segmented-control {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $bright-navy-blue;
    border-bottom: 1px solid $bright-navy-blue;
    @extend .caption;
    padding: 3px 12px;
    color: $bright-navy-blue;

    &:first-child {
      border-left: 1px solid $bright-navy-blue;
      border-right: 1px solid $bright-navy-blue;
      border-radius: 100px 0px 0px 100px;
    }
    &:last-child {
      border-right: 1px solid $bright-navy-blue;
      border-left: 1px solid $bright-navy-blue;
      border-radius: 0 100px 100px 0px;
    }
    p {
      margin-bottom: 0;
    }
  }
}