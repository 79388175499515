.thank-using {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
        margin: 0;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.4px;
        color: $charcoal;
    }
    .website-name{
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.4px;
        color: $silver-chalice;
    }
}
