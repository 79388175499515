.label-with-icon-container {
  display: flex;
  width: fit-content;
  height: fit-content;
  .label-with-icon-inner {
    display: flex;
    align-items: center;
    &.vertical {
      flex-direction: column;

      .label-with-icon-title {
        margin-top: 10px;
        margin-left: 0;
      }
    }
    .icon {
      width: 32px;
      height: 32px;
      img {
        max-width: 100%;
        height: auto;
        display: block;
      }
    }
    .label-with-icon-title {
      @extend .caption;
      margin-left: 6px;
      p {
        margin-bottom: 0;
      }
    }
  }
}