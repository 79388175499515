.Job-rating-compliments {
  padding: 24px 20px;
  background-color: $doctor;
  border-radius: 20px 20px 0 0;
}

.Job_Rating_Items {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  .Job-Rating-compliments-item {
    flex: 1 1 50%;
    margin: 0 0 16px;
    padding: 0 8px;
    label {
      display: flex;
      align-items: center;
      background-color: #FAFBFC;
      color: #949494;
      border-radius: 8px;
      font-weight: 500;
      width: 100%;
      height: 100%;
      margin: 0;
      @include ca-5-shadow();
      .J_R_Complements_Label_Icon{
        float: left;
        width: 25%;
        height: 100%;
      }
      .J_R_Complements_Label_Title{
        padding: 16px;
        h5{
          color: #3B424A;
          font-size: 13px;
          margin: 0;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
  }
}

.Job-rating-compliments .Job-Rating-compliments-item input[type=checkbox] {
  display: none;
}
.Job_Rating_Comment {
  padding: 0 4px;
  h2 {
    margin-top: 24px;
    color: $charcoal;
    @extend .button;
    padding: 0 4px;
    margin: 16px 0 8px;
  }
}
.Job_Rating_Comment_Text_Area{
  border: 0;
  background: $doctor;
  width: 100%;
  outline: none;
}


.Job-rating-compliments input[type=checkbox]:checked ~ .check {
  background: #0057AD;
  .J_R_Complements_Label_Title{
    h5{
      color: #fff;
    }
  }
}
.Feed_Back_Text{
  text-align: center;
  font-weight: 400;
  color: #3B424A;
  @extend .subtitle1;
  margin: 8px 0 24px;
}

.compliments-list {
  .job-information-container {
    padding-top: 0;
  }
  .compliments-container {
    h2 {
      color: $charcoal;
      @extend .button;
      padding: 0 12px;
      margin: 16px 0 8px;
    }
  }
  .feedback-text{
    @extend .h6;
    color: $charcoal;
    margin: 0;
    text-align: center;
  }
  .feedback-text-subtitle {
    @extend .body1;
    margin: 0;
    color: $spanish-gray;
    text-align: center;
    padding-bottom: 8px;
    border-bottom: 1px solid $grainsboro;
  }
}