.saved-places-address-detail {
    height: 100%;
    .title-bar-container {
        height: 44px;
        background-color: white;
        z-index: 100;
    }
    .saved-places-form {
        border-radius: 24px 24px 0px 0px;
        height: 70%;
        z-index: 2;
        position: relative;
        background: white;
        margin-top: 28px;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
        .saved-places-address {
            padding-top: 22px;
            padding-bottom: 100px;
        }
        .form-group-container {
            background-color: white;
        }
        .form-group-container-textarea textarea {
            background-color: white;
        }
    }
    .full-height {
        height: 100%;
        z-index: unset;
        .saved-places-address {
            padding-bottom: 100px;
        }
    }
}

.saved-places-address {
    padding-top: 70px;
    .saved-place-type {
        display: flex;
        padding: 8px;
        p {
            margin: 0 0 0 16px;
            @extend .h6;
            color: $charcoal;
        }
    }
    .saved-places-address-container {
        padding: 0 25px 20px;
        &.input-error {
            .form-group-container {
                border: 1px solid $cinnabar;
                border-radius: 8px;
                transition: border-color .2s linear;
            }
        }
        .saved-places-address-selected {
            display: flex;
            align-items: center;
            padding: 0 8px 8px;
            border-bottom: 1px solid $grainsboro;
            p {
                margin: 0;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 19px;
                letter-spacing: 0.25px;
                color: #3B424A;
            }
        }
        .form-group-container-checkbox {
            margin-top: 12px;
            padding: 0 8px;
        }
    }
    .form-group-container {
        label {
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: $spanish-gray;
        }
        input {
            width: 100%;
            &::placeholder {
                color: $grainsboro;
                font-weight: normal;
                font-size: 13px;
                line-height: 18px;
            }
        }
    }
}
.saved-places-address-confirm{
    position: fixed;
    width: 100%;
    bottom: 0;
    margin-top: 16px;
    padding: 20px;
    background-color: $doctor;
    @media (min-width: 768px) {
        max-width: 412px;
    }
    .cancel-button {
        margin-top: 8px;
        &.button-container.MuiButtonBase-root.MuiButton-root {
            background-color: $doctor;
            border: 0;
            color: $bleu-de-france;
            padding: 8px;
        }
    }
    .button-container.MuiButtonBase-root.MuiButton-root.Mui-disabled {
        background-color: $bleu-de-france;
        color: $doctor;
        opacity: .6;
        padding: 8px;
    }
    button {
        width: 100%;
    }
}
.container-checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 400;
    color: $charcoal;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: $doctor;
        border-radius: 3px;
        border: 1px solid $grainsboro;
    }

    &:hover input ~ .checkmark {
        background-color: $doctor;
    }

    input:checked ~ .checkmark {
        background-color: $doctor;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    .checkmark:after {
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid $bleu-de-france;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
.form-group-container-textarea {
    &.form-group-container {
        border-bottom: 1px solid $grainsboro;
        padding: 0 8px;
    }
    label {
        color: $gunmetal;
        font-size: 12px;
        line-height: 18px;
        font-weight: normal;
    }
    textarea {
        width: 100%;
        background-color: $doctor;
        border: 0;
        padding: 0;
        &:focus {
            outline: none;
        }
        &::placeholder {
            @extend .body2;
            color: $grainsboro;
        }
        &::-webkit-scrollbar {
            width: 0;
        }
        &::placeholder, &:-moz-placeholder, &:-ms-input-placeholder, &::-webkit-input-placeholder {
            @extend .body2;
            color: $grainsboro;
        }
    }
}
.google-map-wrapper {
    height: 30%;
    top: 42px;
    position: relative;
    #google-map {
        top: 0 !important;
        height: 100%;
    }
    .edit-location-btn {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        height: 100%;
        top: 0;
        z-index: 2;
        position: absolute;
        bottom: 14px;
        display: flex;
        width: 100%;
        justify-content: center;
        p {
            position: absolute;
            bottom: 25px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.25px;
            color: #0C87FA;
        }
    }
    .location-search-map-icon {
        z-index: 1;
        position: relative !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        //position: fixed;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        svg {
            &.Pin-icon {
                width: 40px;
                height: 40px;
                transition: all 0.8s ease 0s;
            }
            &.Ellipse-icon {
                // position: fixed;
                left: 49%;
                top: 49%;
                z-index: 10;
                width: 8px;
                fill: #4c4c4c70;
            }
        }
        &.dragged {
            svg {
                &.Pin-icon {
                    transition: all 0.8s ease 0s;
                    top: 42%;
                }
            }
        }
    }

}
