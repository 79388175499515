.rce-mbox-file.voice {
	display: flex;
	align-items: center;
	.lottie-animation {
		> div {
			height: 25px !important;
			width: 150px !important;	
		}
		svg {
			height: 25px !important;
			width: 150px !important;
		}
	}
}
.rce-mbox-file {
	button {
		background: $columbia-blue;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
		outline: none;
		box-shadow: none;
		border: none;
		padding: 0;
		.lds-ring {
			display: inline-block;
			position: absolute;
			width: 27px;
			height: 27px;
			left: 5px;
			top: 7px;
			div {
				width: 27px;
				height: 27px;
			}
		}
	}
	button.voice-play {
		color: #05b3ff;
		svg {
			font-size: 25px;
		}
	}
	button.voice-pause {
		svg {
			font-size: 25px;
		}
		color: #05b3ff;
	}
}
.voice-partner {
	.rce-mbox-file {
		button.voice-play {
			color: #004483;
			background-color: #0C87FA;
			height: 31px;
		}
		button.voice-pause {
			color: #004483;
			background-color: #0C87FA;
			height: 31px;
		}
	}
}
