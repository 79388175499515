.rce-container-mbox {
    &.management,
    &.enterprise {
        .rce-mbox {
            padding: 0 20px 3px;
            .rce-mbox-body {
                background-color: $aero-blue;
                border-radius: 8px;
                padding: 16px;
            }     
        }
        .rce-mbox-sender-name {
            span {
                // text-transform: uppercase;
                color: $dim-gray;
                @extend .overline;
            }
        }
        .timezone {
            display: flex;
            justify-content: flex-end;
            color: $spanish-gray;
            @extend .assistive;
        }
    }
}